import React, { memo } from "react";
import { useSelector } from "react-redux";
import { EduCourseListTypes, EduPageViews } from "../constants";
import { PageNavLink } from "../../../lib";
import { AuthSelectors } from "../../../state";

interface JewishUMenuDropdownProps {
  path: string;
  programScheduleId: number | string;
}
function JewishUMenuDropdown({
  path,
  programScheduleId,
}: JewishUMenuDropdownProps) {
  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const allowViewEduStudents = useSelector(AuthSelectors.canManageEduStudents);
  const allowScheduleCourses = useSelector(AuthSelectors.canManageEduSchedule);

  return (
    <ul className="accent-text-dark uppercase-text">
      {allowScheduleCourses ? (
        <li className="mb-16">
          <PageNavLink
            activeClassName="active-link"
            className="link-text-secondary block no-hover"
            to={path}
            params={{
              pageView: EduPageViews.courses,
              programScheduleId,
            }}
          >
            Courses
          </PageNavLink>
          <ul className="accent-text small-text mt-16 ml-16 mb-16">
            <PageNavLink
              className="block mb-16 link-text-secondary"
              to={path}
              params={{
                pageView: EduPageViews.courses,
                programScheduleId,
              }}
              query={{ type: EduCourseListTypes.all }}
            >
              All Courses
            </PageNavLink>
            <PageNavLink
              className="link-text-secondary block"
              to={path}
              params={{
                pageView: EduPageViews.courses,
                programScheduleId,
              }}
              query={{ type: EduCourseListTypes.my }}
            >
              My Courses
            </PageNavLink>
          </ul>
        </li>
      ) : (
        <li className="mb-16">
          <PageNavLink
            activeClassName="active-link"
            to={path}
            params={{
              pageView: EduPageViews.courses,
              programScheduleId,
            }}
          >
            My Courses
          </PageNavLink>
        </li>
      )}
      {allowViewEduStudents && (
        <li className="mb-16">
          <PageNavLink
            activeClassName="active-link"
            to={path}
            params={{
              pageView: EduPageViews.students,
              programScheduleId,
            }}
          >
            Students
          </PageNavLink>
        </li>
      )}
      {isShliach && (
        <>
          {/* TODO COC-4612: Temporarily hiding billing page */}
          {/* <li className="mb-16">
            <PageNavLink
              activeClassName="active-link"
              to={path}
              params={{
                pageView: EduPageViews.billing,
                programScheduleId: programScheduleId,
              }}
            >
              Billing
            </PageNavLink>
          </li> */}
          <li>
            <PageNavLink
              activeClassName="active-link"
              to={path}
              params={{
                pageView: EduPageViews.settings,
                programScheduleId: programScheduleId,
              }}
            >
              Settings
            </PageNavLink>
          </li>
        </>
      )}
    </ul>
  );
}

export default memo(JewishUMenuDropdown);

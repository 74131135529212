import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@material-ui/core";
import {
  convertRsvpRegistrationToStudent,
  convertThreadRecipientToStudent,
  MessageScheduleTypes,
  SmsRecipients,
  WizardProps,
  WizardStepIdentifiers,
  wizardSteps,
} from "./WizardHelpers";
import { useMobile } from "../../../themes";
import {
  BaseResponse,
  ChabadHouseSmsEnrollment,
  RsvpRegistrationPerson,
  StudentDetails,
  StudentFilters,
} from "../../../lib/apiRequests/ApiTypes";
import {
  getResourceRegistrations,
  getEventRegistrations,
  getChabadHouseEnrollment,
} from "../../../lib/apiRequests/RsvpApiRequests";
import { getMessageThread } from "../../../lib/apiRequests/SmsApiRequests";
import WizardError from "./WizardError";
import Loader from "../../../components/Loader";
import { Close } from "@material-ui/icons";

interface WizardErrors {
  enrollmentError?: string;
  dataRetrievalError?: string;
}

export const SmsWizard = React.memo(function SmsWizard(props: WizardProps) {
  const { eventScheduleId, onClose, resourceId, openToStep, threadId } = props;

  const [currentStep, setCurrentStep] = useState<number>();
  const [errors, setErrors] = useState<WizardErrors>({});
  const [completedSteps, setCompletedSteps] = useState(new Set());
  const [recipients, setRecipients] = useState<SmsRecipients>({
    filteredStudents: [],
    additionalStudents: [],
  });
  const [chabadHouseEnrollmentData, setChabadHouseEnrollmentData] =
    useState<ChabadHouseSmsEnrollment>();
  const [studentFilters, setStudentFilters] = useState<StudentFilters>({
    campusID: [],
    chabadHouseTags: [],
    classes: [],
    excludeParticipatedInPrograms: [],
    isJewish: false,
    includeArchived: false,
    graduationYear: [],
    programsParticipated: [],
  });
  const [messageText, setMessageText] = useState("");
  const [messageImage, setMessageImage] = useState<string | null>(null);
  const [messageScheduleType, setMessageScheduleType] =
    useState<MessageScheduleTypes | null>(null);
  const [loading, setLoading] = useState(true); // loading is defaulted to true before wizard data has been retrieved

  const isMobile = useMobile();

  function resetState() {
    setCurrentStep(undefined);
    setErrors({});
    setCompletedSteps(new Set());
    setRecipients({
      filteredStudents: [],
      additionalStudents: [],
    });
    setChabadHouseEnrollmentData(undefined);
    setStudentFilters({
      campusID: [],
      chabadHouseTags: [],
      classes: [],
      excludeParticipatedInPrograms: [],
      isJewish: false,
      includeArchived: false,
      graduationYear: [],
      programsParticipated: [],
    });
    setMessageText("");
    setMessageScheduleType(null);
    setMessageImage(null);
  }

  const next = useCallback(() => {
    if (currentStep !== undefined) {
      setCompletedSteps((completedSteps) => completedSteps.add(currentStep));
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep]);

  const back = useCallback(() => {
    if (currentStep) {
      setCurrentStep(currentStep - 1);
    }
  }, [currentStep]);

  const addRecipients = useCallback((students: StudentDetails[]) => {
    setRecipients((recipients) => ({
      ...recipients,
      additionalStudents: [...recipients.additionalStudents, ...students],
    }));
  }, []);

  const removeRecipients = useCallback((students: StudentDetails[]) => {
    setRecipients((recipients) => {
      const newAdditional = recipients.additionalStudents.filter(
        (student) => !students.find((p) => p.personID === student.personID),
      );
      const newFiltered = recipients.filteredStudents.filter(
        (student) => !students.find((p) => p.personID === student.personID),
      );
      return {
        filteredStudents: newFiltered,
        additionalStudents: newAdditional,
      };
    });
  }, []);

  const loadRsvpEnrollment = useCallback(async () => {
    const { error, data } = await getChabadHouseEnrollment();
    if (error) {
      setErrors((errors) => ({ ...errors, dataRetrievalError: error }));
    } else if (!data?.isChabadHouseEnrolledInOutgoingMessaging) {
      setErrors((errors) => ({
        ...errors,
        enrollmentError:
          "Your Chabad House is not enrolled in outgoing messaging",
      }));
    } else {
      setChabadHouseEnrollmentData(data);
      return data;
    }
  }, []);

  const loadRecipients = useCallback(
    async (chabadHouseEnrollmentData) => {
      if (chabadHouseEnrollmentData) {
        const { id: rsvpEnrollmentId } = chabadHouseEnrollmentData;
        if (rsvpEnrollmentId) {
          let response: BaseResponse<RsvpRegistrationPerson[]> = {};
          if (eventScheduleId) {
            response = await getEventRegistrations(rsvpEnrollmentId, {
              export: true,
              eventScheduleId: eventScheduleId,
              sortBy: "Name",
            });
          } else if (resourceId) {
            response = await getResourceRegistrations(rsvpEnrollmentId, {
              export: true,
              resourceId: resourceId,
              sortBy: "Name",
            });
          }
          if (response?.error) {
            setErrors((errors) => ({
              ...errors,
              dataRetrievalError: response.error,
            }));
          } else if (response?.data) {
            addRecipients(response.data.map(convertRsvpRegistrationToStudent));
          }
        }
      }
    },
    [eventScheduleId, resourceId, addRecipients],
  );

  const getThreadData = useCallback(
    async (chabadHouseEnrollmentData) => {
      if (threadId) {
        const { data, error } = await getMessageThread(
          chabadHouseEnrollmentData.forChabadHouseID,
          threadId,
        );
        if (error) {
          setErrors((errors) => ({ ...errors, dataRetrievalError: error }));
        } else if (data && data[0]?.people) {
          const people = data[0].people
            .map((p) => convertThreadRecipientToStudent(p))
            .filter((p) => p);

          addRecipients(people as StudentDetails[]);
        }
      }
    },
    [addRecipients, threadId],
  );

  const loadData = useCallback(async () => {
    setLoading(true);
    const chabadHouseEnrollmentData = await loadRsvpEnrollment();
    if (chabadHouseEnrollmentData) {
      await loadRecipients(chabadHouseEnrollmentData);
      if (chabadHouseEnrollmentData.forChabadHouseID) {
        await getThreadData(chabadHouseEnrollmentData);
      }
    }
    setLoading(false);
  }, [getThreadData, loadRecipients, loadRsvpEnrollment]);

  useEffect(() => {
    loadData();
    //only call on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // validate that we have the correct props to skip to that step
    if (openToStep) {
      if (openToStep === WizardStepIdentifiers.ComposeMessage) {
        if (!threadId && !eventScheduleId && !resourceId) {
          setErrors((errors) => ({
            ...errors,
            dataRetrievalError: "Cannot retrieve data",
          }));
        }
      }
      const skippedSteps = new Set();
      Array.from({ length: openToStep }).forEach((_, i) => skippedSteps.add(i));
      setCompletedSteps(skippedSteps);
      setCurrentStep(openToStep);
    } else {
      setCurrentStep(0);
    }
  }, [eventScheduleId, resourceId, openToStep, threadId]);
  const StepComponent =
    currentStep !== undefined ? wizardSteps[currentStep]?.component : null;

  return (
    <Dialog
      open={true}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      fullWidth
      maxWidth="lg"
      PaperProps={
        isMobile
          ? {
              style: {
                minHeight: "100%",
                maxHeight: "100%",
              },
            }
          : {}
      }
    >
      <DialogContent className={isMobile ? "mb-64" : ""}>
        <div className="flex flex-justify-space">
          <Typography variant="h4" className="ml-8 mt-8">
            Create new SMS message
          </Typography>
          {isMobile && (
            <IconButton size="small" onClick={onClose}>
              <Close />
            </IconButton>
          )}
        </div>
        <Stepper
          activeStep={currentStep}
          style={{ width: "100%", paddingLeft: 0 }}
        >
          {wizardSteps.map(({ name }, index) => (
            <Step key={name}>
              <StepButton
                onClick={() =>
                  currentStep && index <= currentStep && setCurrentStep(index)
                }
                completed={completedSteps.has(index)}
                disabled={currentStep === wizardSteps.length - 1}
              >
                {isMobile ? "" : name}
              </StepButton>
            </Step>
          ))}
        </Stepper>

        {loading ? (
          <Loader />
        ) : errors.enrollmentError ? (
          <WizardError
            errorMessage={errors.enrollmentError}
            onClose={onClose}
          />
        ) : errors.dataRetrievalError ? (
          <WizardError
            errorMessage={errors.dataRetrievalError}
            onClose={onClose}
            onRefresh={resetState}
          />
        ) : (
          <div className="ml-8">
            {StepComponent && chabadHouseEnrollmentData && (
              <StepComponent
                {...props}
                back={back}
                chabadHouseEnrollmentData={chabadHouseEnrollmentData}
                studentFilters={studentFilters}
                messageImage={messageImage}
                next={next}
                onClose={onClose}
                messageText={messageText}
                messageScheduleType={messageScheduleType}
                recipients={recipients}
                removeRecipients={removeRecipients}
                setAdditionalStudents={(students: StudentDetails[]) =>
                  setRecipients({
                    ...recipients,
                    additionalStudents: students,
                  })
                }
                setMessageImage={setMessageImage}
                setStudentFilters={setStudentFilters}
                setFilteredStudents={(students: StudentDetails[]) =>
                  setRecipients({
                    ...recipients,
                    filteredStudents: students,
                  })
                }
                setMessageText={setMessageText}
                setMessageScheduleType={setMessageScheduleType}
              />
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
});

import React from "react";
import SmsAddCredit from "./SmsAddCredit.vm";
import { Dialog, DialogContent } from "@material-ui/core";
import { DialogHeader } from "../../components";

export default function SmsAddCreditModal(props) {
  const { show, onPaymentComplete, onClose, chabadHouseID } = props;
  return (
    <Dialog open={show} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogHeader title="Add Credit" handleClose={onClose} />

      <DialogContent>
        <SmsAddCredit
          onPaymentComplete={onPaymentComplete}
          chabadHouseID={chabadHouseID}
          onClose={onClose}
        />
      </DialogContent>
    </Dialog>
  );
}

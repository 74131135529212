import {
  AuthRequest,
  FileUploader,
  createUniqueNameForFile,
  trimTrailingSlash,
} from "../../lib";

/** @enum {string} */
const ImageType = {
  family: { path: "family" },
  house: { path: "house" },
  house_logo: { path: "house_logo" },
  profile: { path: "profile" },
  school_logo: { path: "school_logo" },
  banner: { path: "banner" },
  event_banner: { path: "event_banner" },
  sms_img_attachment: { path: "sms_img_attachment" },
};

const DocType = {
  life_insurance_bdf: { path: "life_insurance_bdf" },
};

export const SysActions = {
  GET_SYSTEM_DATA: "GET_SYSTEM_DATA",
  GET_PUBLIC_PORTAL_REDIRECTS: "GET_PUBLIC_PORTAL_REDIRECTS",
  UPLOAD_IMAGE: "UPLOAD_IMAGE",

  getSystemData() {
    return async function gettingSystemData(dispatch) {
      const response = await AuthRequest.get("System", {
        params: {
          portalRedirectsDomain: window.location.hostname,
        },
      });
      dispatch({
        type: SysActions.GET_SYSTEM_DATA,
        payload: response.data.payload,
      });
    };
  },
  getPublicPortalRedirects() {
    return async function gettingPublicPortalRedirects(dispatch) {
      const response = await AuthRequest.get("System/Public/PortalRedirects", {
        params: {
          portalRedirectsDomain: window.location.hostname,
        },
      }).catch(() =>
        console.error("Failed to retrieve public portal redirects"),
      );

      dispatch({
        type: SysActions.GET_PUBLIC_PORTAL_REDIRECTS,
        payload: response?.data?.payload,
      });
    };
  },
  /** @param {File} file
   * @param {FileType} fileType
   */
  uploadFile(file, fileType, isSecure) {
    fileType = ("" + fileType).trim();
    const fileTypeInfo = ImageType[fileType] || DocType[fileType];
    if (!fileTypeInfo) {
      throw new Error(`FileType not found: ${fileTypeInfo}`);
    }
    return async function uploadingFile(/* dispatch, getState */) {
      let {
        data: {
          payload: {
            blobContainerURI,
            // container,
            sas: sasQueryString,
          },
        },
      } = await AuthRequest.get(
        `/Storage/sas${isSecure ? "?isSecure=true" : ""}`,
      );
      blobContainerURI = trimTrailingSlash(blobContainerURI);
      const fileName = createUniqueNameForFile(file);
      const fileURL = `${blobContainerURI}/${fileTypeInfo.path}/${fileName}`;
      const uploadURL = `${fileURL}${sasQueryString}`;
      const uploader = new FileUploader(file, uploadURL);
      await uploader.start();
      return fileURL;
    };
  },
  getSecureBlobURL(blobURI) {
    return async (/* dispatch, getState */) => {
      let {
        data: {
          payload: { sas: sasQueryString },
        },
      } = await AuthRequest.get(`/Storage/sas/blob?blobUri=${blobURI}`);

      const secureFileURL = `${blobURI}${sasQueryString}`;

      return secureFileURL;
    };
  },
};

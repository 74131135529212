import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useParams, useLocation } from "react-router-dom";
import { Button, Box, Grid } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { Navigation, PageURL, useQuery } from "../../lib";
import ActiveMessageModal from "./ActiveMessageModal";
import { messagesView } from "../rsvp/myRsvp/RsvpMessageUtils";
import { SmsActions, useAction } from "../../state";
import { useSelector } from "react-redux";
import { SmsSelectors } from "../../state/sms/selectors";
import { RsvpSelectors } from "../../state/rsvp/selectors";
import { NoMessages } from "./NoMessages";
import SmsMessageDetails from "./SmsMessageDetails";
import SmsMessageList from "./SmsMessageList";
import Loader from "../../components/Loader";
import { SmsWizard } from "./newWizard/Wizard";
import { WizardStepIdentifiers } from "./newWizard/WizardHelpers";

export default function SmsMailbox() {
  const params = useParams();
  const query = useQuery();

  const { view } = params;
  const { search, threadId: queryThreadId } = query;
  const { pathname } = useLocation();

  const getMailboxMessages = useAction(SmsActions.getMailboxMessages);
  const getSmsScheduledMessages = useAction(SmsActions.getSmsScheduledMessages);
  const clearSmsInbox = useAction(SmsActions.clearSmsInbox);
  const clearSmsOutbox = useAction(SmsActions.clearSmsOutbox);
  const clearSmsScheduledMessages = useAction(
    SmsActions.clearSmsScheduledMessages,
  );
  const clearSmsMessageThread = useAction(SmsActions.clearSmsMessageThread);

  const threadScrollDiv = useRef();
  const activeMessageRef = useRef();

  const inboxData = useSelector(SmsSelectors.inbox);
  const outboxData = useSelector(SmsSelectors.outbox);
  const scheduledData = useSelector(SmsSelectors.scheduledMessages);
  const messageThread = useSelector(SmsSelectors.messageThread);
  const messageThreadLoading = useSelector(SmsSelectors.messageThreadLoading);
  const forChabadHouseID = useSelector(RsvpSelectors.forChabadHouseID);
  const getMesThread = useAction(SmsActions.getMessageThread);
  const getSchMessage = useAction(SmsActions.getScheduledMessage);
  const timezoneID = useSelector(RsvpSelectors.timezoneID);

  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [activeMessage, setActiveMessage] = useState({});
  const [showActiveMessageModal, setShowActiveMessageModal] = useState(false);
  const [showSendSmsModal, setShowSendSmsModal] = useState(false);

  const data = useMemo(() => {
    return (
      (view === messagesView.inbox
        ? inboxData
        : view === messagesView.sent
        ? outboxData
        : scheduledData) || []
    );
  }, [inboxData, outboxData, scheduledData, view]);

  const getMessages = useCallback(
    async (page = 1, shouldReload = true) => {
      if (!forChabadHouseID) {
        return;
      }

      if (!data || shouldReload) {
        const messageDirection = view === messagesView.inbox ? 2 : 1;
        view === messagesView.scheduled
          ? getSmsScheduledMessages(page, 15, forChabadHouseID, search)
          : getMailboxMessages(
              page,
              15,
              forChabadHouseID,
              messageDirection,
              search,
            );
      }
    },
    [
      data,
      forChabadHouseID,
      getMailboxMessages,
      getSmsScheduledMessages,
      search,
      view,
    ],
  );

  const resetMailboxes = useCallback(async () => {
    await clearSmsInbox();
    await clearSmsOutbox();
    await clearSmsScheduledMessages();
    await clearSmsMessageThread();
    setActiveMessage({});
    setRefresh(true);
  }, [
    clearSmsInbox,
    clearSmsMessageThread,
    clearSmsOutbox,
    clearSmsScheduledMessages,
  ]);

  useEffect(() => {
    if (queryThreadId) {
      setShowSendSmsModal(true);
    }
  }, [queryThreadId]);

  useEffect(() => {
    resetMailboxes();
  }, [forChabadHouseID, resetMailboxes, search]);

  useEffect(() => {
    if (refresh) {
      setPageNumber(1);
      setRefresh(false);
      getMessages(1);
    }
  }, [refresh, getMessages]);

  const handleFetchMore = useCallback(() => {
    const page = pageNumber + 1;
    setPageNumber(page);
    getMessages(page);
  }, [getMessages, pageNumber]);

  const getMessageThread = useCallback(
    (message) => {
      setActiveMessage({ ...message, activeClass: "" });
      setActiveMessage({ ...message, activeClass: "active-message" });

      if (view === messagesView.scheduled) {
        getSchMessage(message.messageId);
      } else {
        getMesThread(
          forChabadHouseID,
          message.threadId,
          !message.totalRecipients || message.totalRecipients === 1
            ? message.firstPersonId
            : null,
          view === messagesView.sent,
          message.smsBlastId,
        );
      }

      setTimeout(() => {
        if (threadScrollDiv.current) {
          threadScrollDiv.current.scroll({
            top: activeMessageRef.current?.offsetTop - 272,
            behavior: "smooth",
          });
        }
      }, 200);
    },
    [forChabadHouseID, getMesThread, view, getSchMessage],
  );

  // we currently only support reply for students, not OtherPeople
  const reply = useCallback(async () => {
    if (view === messagesView.inbox && messageThread.length > 0) {
      setShowActiveMessageModal(false);
      setShowSendSmsModal(true);
    }
  }, [messageThread, view]);

  const messageThreadSection = useMemo(() => {
    const allowReply =
      view === messagesView.inbox && // can only reply to messages in the inbox
      messageThread.length > 0 &&
      !!activeMessage.firstPersonStudentId; // we are not currently supporting SMS to non-students

    return messageThreadLoading ? (
      <Loader />
    ) : !!Object.keys(activeMessage)?.length ? (
      <div className="sms-thread-inner-container">
        <div
          ref={(ref) => (threadScrollDiv.current = ref)}
          className="sms-thread-content-container"
        >
          {messageThread.map((m, i) => (
            <div
              key={i}
              ref={(ref) =>
                m?.messageId === activeMessage.messageId
                  ? (activeMessageRef.current = ref)
                  : ""
              }
            >
              <SmsMessageDetails
                message={m}
                resetMailboxes={resetMailboxes}
                timezoneID={timezoneID}
                view={view}
                activeMessageId={activeMessage.messageId}
                index={i}
                anonymousPhoneNumber={
                  !activeMessage.firstPersonId &&
                  (activeMessage.toPersonPhone || activeMessage.fromPersonPhone)
                }
              />
            </div>
          ))}
        </div>
        {allowReply && (
          <div className="button-container">
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              className="ml-16 reply-button"
              onClick={reply}
            >
              Reply <Send fontSize="small" style={{ marginLeft: 8 }} />
            </Button>
          </div>
        )}
      </div>
    ) : (
      <NoMessages />
    );
  }, [
    activeMessage,
    messageThread,
    messageThreadLoading,
    reply,
    resetMailboxes,
    timezoneID,
    view,
  ]);

  return (
    <Box className="sms-mailbox-page">
      <Grid container style={{ height: "100%" }}>
        <Grid item md={3} xs={12}>
          <SmsMessageList
            activeMessage={activeMessage}
            data={data}
            getMessageThread={getMessageThread}
            params={params}
            handleFetchMore={handleFetchMore}
            setShowActiveMessageModal={setShowActiveMessageModal}
            timezoneID={timezoneID}
          />
        </Grid>
        <Grid
          item
          md={9}
          xs={1}
          className="sms-thread-container mobile-hidden tablet-hidden"
        >
          {messageThreadSection}
        </Grid>
      </Grid>
      {showActiveMessageModal && (
        <ActiveMessageModal
          allowReply={
            view === messagesView.inbox && !!activeMessage.firstPersonStudentId
          } // we are not currently supporting SMS to non-students}
          show={showActiveMessageModal}
          close={() => setShowActiveMessageModal(false)}
          reply={reply}
          activeMessage={messageThread?.find(
            (m) => m.messageId === activeMessage.messageId,
          )}
          view={view}
          resetMailboxes={resetMailboxes}
          timezoneID={timezoneID}
        />
      )}
      {showSendSmsModal && (
        <SmsWizard
          onClose={() => {
            setShowSendSmsModal(false);
            //redirect to clear query params so wizard doesn't pop open if they hit refresh
            Navigation.redirect(
              PageURL.to(pathname, null, { ...query, threadId: undefined }),
            );
          }}
          openToStep={WizardStepIdentifiers.ComposeMessage}
          threadId={queryThreadId || messageThread[0]?.threadId}
        />
      )}
    </Box>
  );
}

import React from "react";
import { Button, DialogActions } from "@material-ui/core";
import { useMobile } from "../../../themes";

interface WizardFooterProps {
  back?: () => void;
  customNextButton?: any;
  onClose: () => void;
  next?: () => void;
  nextDisabled?: boolean;
  nextLoading?: boolean;
}

export const WizardFooter = React.memo(function WizardFooter(
  props: WizardFooterProps,
) {
  const { back, customNextButton, next, nextDisabled, nextLoading, onClose } =
    props;
  const isMobile = useMobile();
  return (
    <DialogActions
      className="mt-16 mb-16"
      style={{ justifyContent: "space-between", padding: 0 }}
    >
      <div>
        {!isMobile && (
          <Button
            className="mr-16"
            onClick={onClose}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
        )}

        {back && (
          <Button onClick={back} color="secondary" variant="contained">
            Back
          </Button>
        )}
      </div>
      {customNextButton ||
        (next && (
          <Button
            disabled={nextDisabled}
            onClick={next}
            variant="contained"
            color="primary"
          >
            {nextLoading ? "Loading..." : "Next"}
          </Button>
        ))}
    </DialogActions>
  );
});

import ApiCallErrorMessageHandler from "../ApiCallErrorMessageHandler";
import { AuthRequest } from "../AuthRequest";
import { BaseResponse, ChabadHouseTag, School } from "./ApiTypes";

export async function getStudentGroups() {
  const enrollmentResponse: BaseResponse<ChabadHouseTag[]> = {};
  try {
    const response = await AuthRequest.get("Shluchim/studentGroups");
    enrollmentResponse.data = response.data;
  } catch (err) {
    enrollmentResponse.error = ApiCallErrorMessageHandler(err);
  }
  return enrollmentResponse;
}

export async function getShliachCampuses(shliachId: number) {
  const enrollmentResponse: BaseResponse<School[]> = {};
  try {
    const response = await AuthRequest.get(`Shluchim/${shliachId}/campuses`);
    enrollmentResponse.data = response.data;
  } catch (err) {
    enrollmentResponse.error = ApiCallErrorMessageHandler(err);
  }
  return enrollmentResponse;
}

import React, { memo, useCallback, useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import StripeProvider from "../../../../components/StripeProvider";
import CreditsBillingBreakdown from "./CreditsBillingBreakdown";
import CreditsBillingStatus from "./CreditsBillingStatus";
import MembershipStatus from "./MembershipStatus";
import { BillingContainerStyled, BillingHeaderStyled } from "./Billing.styles";
import { EduBillingCharges, EduEnrollment } from "./BillingTypes";
import { EduActions, useAppDispatch } from "../../../../state";

const { REACT_APP_STRIPE_API_KEY } = process.env;

interface BillingContainerProps {
  enrollment: { data: EduEnrollment };
  pageRoute: any;
}

function BillingContainer({
  enrollment: _enrollment,
  pageRoute,
}: BillingContainerProps) {
  const dispatch = useAppDispatch();

  const { data: enrollment } = _enrollment;

  const [billingCharges, setBillingCharges] = useState<EduBillingCharges>();
  const [billingChargesError, setBillingChargesError] = useState(false);

  const [billingRefreshCount, setBillingRefreshCount] = useState(0);

  const retrieveBillingCharges = useCallback(async () => {
    const { data, error } = await dispatch(
      EduActions.getEduEnrollmentBillingCharges(enrollment.id),
    );
    if (error) {
      setBillingChargesError(true);
    } else {
      setBillingCharges(data);
    }
  }, [dispatch, enrollment.id]);

  useEffect(() => {
    retrieveBillingCharges();
  }, [billingRefreshCount, retrieveBillingCharges]);

  return (
    <StripeProvider apiKey={REACT_APP_STRIPE_API_KEY}>
      <BillingContainerStyled>
        <Typography variant="h4" className="mb-24">
          Billing
        </Typography>
        <BillingHeaderStyled>
          <MembershipStatus
            enrollment={enrollment}
            renewalDisabled={billingCharges?.didLastChargeFail}
          />
          <CreditsBillingStatus
            enrollment={enrollment}
            billingCharges={billingCharges}
            billingChargesError={billingChargesError}
            refreshBillingData={() =>
              setBillingRefreshCount(billingRefreshCount + 1)
            }
          />
        </BillingHeaderStyled>
        <CreditsBillingBreakdown
          billingRefreshCount={billingRefreshCount}
          enrollment={enrollment}
          pageRoute={pageRoute}
        />
      </BillingContainerStyled>
    </StripeProvider>
  );
}

export default memo(BillingContainer);

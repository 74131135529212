import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { EduCourseListTypes, EduPageViews } from "../constants";
import { EduProgramTypes, getEduProgramLogoSrc } from "../eduUtils";
import { PageNavLink } from "../../../lib";
import { AuthSelectors } from "../../../state";

function EduHeader(props) {
  const {
    eduPrograms,
    enrollment,
    pageRoute: { page, params: pageParams },
    programRoutes,
  } = props;

  const { eduProgramType } = enrollment || {};

  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const allowViewEduStudents = useSelector(AuthSelectors.canManageEduStudents);
  const allowScheduleCourses = useSelector(AuthSelectors.canManageEduSchedule);

  const createLink = useCallback(
    (pageView, text, className, query) => (
      <PageNavLink
        activeClassName="active-link"
        className={className}
        to={page}
        params={{
          pageView,
          programScheduleId: pageParams.programScheduleId,
        }}
        query={query}
      >
        {text}
      </PageNavLink>
    ),
    [page, pageParams.programScheduleId],
  );

  const createLinkLineItem = (eduView, text) => (
    <li key={eduView.pageView}>{createLink(eduView, text)}</li>
  );

  const createCoursesLink = (text, classname, query) =>
    createLink(EduPageViews.courses, text, classname, query);

  const getHeaderMenuItems = () => {
    if (
      eduProgramType === EduProgramTypes.JewishU ||
      eduProgramType === EduProgramTypes.YourIsrael
    ) {
      return (
        <>
          {allowScheduleCourses ? (
            <li className="edu-courses-dropdown-container" key="courses">
              {createCoursesLink(
                "Courses",
                "link-text-secondary block no-hover",
              )}
              <div className="edu-courses-dropdown-box dropdown-box">
                {createCoursesLink(
                  "All Courses",
                  "block link-text-secondary mb-16",
                  { type: EduCourseListTypes.all },
                )}

                {createCoursesLink("My Courses", "block link-text-secondary", {
                  type: EduCourseListTypes.my,
                })}
              </div>
            </li>
          ) : (
            createLinkLineItem(EduPageViews.courses, "My Courses") // type defaults to "my"
          )}
          {allowViewEduStudents &&
            createLinkLineItem(EduPageViews.students, "Students")}
          {isShliach && (
            <>
              {/* TODO COC-4612: Temporarily hiding billing page */}
              {/* {eduProgramType === EduProgramTypes.JewishU &&
                createLinkLineItem(EduPageViews.billing, "Billing")} */}
              {createLinkLineItem(EduPageViews.settings, "Settings")}
            </>
          )}
        </>
      );
    }

    return (
      <>
        {createLinkLineItem(EduPageViews.courses, "Courses")}
        {allowViewEduStudents &&
          createLinkLineItem(EduPageViews.students, "Students")}
      </>
    );
  };

  const getAvailableProgramIcons = useCallback(
    () =>
      eduPrograms
        .filter(
          (
            eduProgram, //only include edu programs that this shliach has access to (included in program routes)
          ) =>
            programRoutes.find(
              (p) => p.scheduleID === eduProgram.programScheduleID,
            ),
        )
        .sort((p1, p2) => (p1.id > p2.id ? 1 : -1))
        .map((eduProgram, index) => (
          <React.Fragment key={eduProgram.id}>
            {index > 0 && <p style={{ color: "#DDDDDD" }}>|</p>}
            <PageNavLink
              className="edu-program-link"
              to={page}
              params={{
                programScheduleId: eduProgram.programScheduleID,
              }}
            >
              <img
                alt={`${eduProgram.name} logo`}
                height="40"
                src={getEduProgramLogoSrc(eduProgram.type, ".jpg")}
              />
            </PageNavLink>
          </React.Fragment>
        )),
    [eduPrograms, page, programRoutes],
  );

  return (
    <div className="edu-subheader mobile-hidden">
      <div className="container flex flex-justify-space full-width flex-align-center">
        <div className="flex flex-align-center">
          {eduPrograms && programRoutes && getAvailableProgramIcons()}
        </div>
        {enrollment && !enrollment.isPending && !enrollment.isExpired && (
          <ul>{getHeaderMenuItems()}</ul>
        )}
      </div>
    </div>
  );
}

export default memo(EduHeader);

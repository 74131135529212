import ApiCallErrorMessageHandler from "../ApiCallErrorMessageHandler";
import { AuthRequest } from "../AuthRequest";
import {
  BaseResponse,
  RsvpRegistrationPerson,
  EventFilters,
  EventSchedule,
  Resource,
  ResourceFilters,
  ChabadHouseSmsEnrollment,
} from "./ApiTypes";

export async function getChabadHouseEnrollment() {
  const enrollmentResponse: BaseResponse<ChabadHouseSmsEnrollment> = {};
  try {
    const response = await AuthRequest.get("RsvpEnrollments/forChabadHouse");
    enrollmentResponse.data = response.data.payload;
  } catch (err) {
    enrollmentResponse.error = ApiCallErrorMessageHandler(err);
  }
  return enrollmentResponse;
}

export async function getEventRegistrations(
  rsvpEnrollmentId: number,
  params: {
    eventScheduleId: number;
    export?: boolean;
    sortBy?: string;
  },
) {
  const recipientsResponse: BaseResponse<RsvpRegistrationPerson[]> = {};

  try {
    const response = await AuthRequest.get(
      `RsvpEnrollments/${rsvpEnrollmentId}/events/registrations`,
      { params },
    );
    recipientsResponse.data = response.data.payload.results;
  } catch (err) {
    recipientsResponse.error = ApiCallErrorMessageHandler(err);
  }
  return recipientsResponse;
}

export async function getResourceRegistrations(
  rsvpEnrollmentId: number,
  params: {
    export?: boolean;
    resourceId: number;
    sortBy?: string;
  },
) {
  const recipientsResponse: BaseResponse<RsvpRegistrationPerson[]> = {};

  try {
    const response = await AuthRequest.get(
      `RsvpEnrollments/${rsvpEnrollmentId}/resources/registrations`,
      { params },
    );
    recipientsResponse.data = response.data.payload.results;
  } catch (err) {
    recipientsResponse.error = ApiCallErrorMessageHandler(err);
  }
  return recipientsResponse;
}

export async function getRsvpEventSchedules(
  rsvpEnrollmentId: number,
  filters: EventFilters,
) {
  const eventsResponse: BaseResponse<EventSchedule[]> = {};
  try {
    const response = await AuthRequest.get(
      `RsvpEnrollments/${rsvpEnrollmentId}/EventSchedules`,
      { params: filters },
    );
    eventsResponse.data = response.data.payload.results;
  } catch (err) {
    eventsResponse.error = ApiCallErrorMessageHandler(err);
  }
  return eventsResponse;
}

export async function getRsvpResources(
  rsvpEnrollmentId: number,
  filters: ResourceFilters,
) {
  const resourcesResponse: BaseResponse<Resource[]> = {};
  try {
    const response = await AuthRequest.get(
      `RsvpEnrollments/${rsvpEnrollmentId}/Resources`,
      { params: filters },
    );
    resourcesResponse.data = response.data.payload.results;
  } catch (err) {
    resourcesResponse.error = ApiCallErrorMessageHandler(err);
  }
  return resourcesResponse;
}

import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SysActions, SystemSelectors, useAppDispatch } from "../../state";
import { PageLink, trimTrailingSlash } from "../../lib";
import Pages from "../../pages";
import Loader from "../../components/Loader";

function NotFound() {
  const dispatch = useAppDispatch();
  const portalRedirects = useSelector(SystemSelectors.portalRedirects);

  const [checkedPortalRedirects, setCheckedPortalRedirects] = useState(false);

  const handlePortalRedirects = useCallback(() => {
    if (!portalRedirects) {
      dispatch(SysActions.getPublicPortalRedirects());
    } else {
      if (portalRedirects.length) {
        const { pathname } = window.location;
        const redirect = portalRedirects.find(
          (r) =>
            r.pathFrom?.toLowerCase() ===
            trimTrailingSlash(pathname)?.toLowerCase(),
        );
        if (redirect) {
          window.location.replace(redirect.pathTo);
          return;
        }
      }
      setCheckedPortalRedirects(true);
    }
  }, [dispatch, portalRedirects]);

  useEffect(() => {
    handlePortalRedirects();
  }, [handlePortalRedirects]);

  if (!checkedPortalRedirects) {
    return (
      <div className="full-page-loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="page-not-found">
      <div>
        <div className="page-not-found-bg" />
        <div className="page-not-found-circles">
          <div>
            <div className="circle1" />
            <div className="circle2" />
          </div>
        </div>
        <div className="rocket-text">
          <p>4</p>
          <img src="/images/rocket.svg" alt="0" />
          <p>4</p>
        </div>
        <p
          className="medium-text fw-500 mt-32 text-center"
          style={{ maxWidth: "330px", color: "#fff", lineHeight: "1.5" }}
        >
          We have Chabad Houses worldwide but we didn't make it to space just
          yet!
        </p>
        <PageLink to={Pages.main.home} className="btn home-btn">
          Back To Home
        </PageLink>
      </div>
    </div>
  );
}

export default memo(NotFound);

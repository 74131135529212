import React from "react";
import ErrorDisplay from "../../../components/ErrorDisplay";
import { Button } from "@material-ui/core";

interface WizardErrorProps {
  errorMessage: string;
  onClose?: () => void;
  onRefresh?: () => void;
}

export default function WizardError(props: WizardErrorProps) {
  const { errorMessage, onClose, onRefresh } = props;
  return (
    <div className="flex flex-column flex-align-center">
      <ErrorDisplay errorMessage={errorMessage} />
      <div className="flex mt-16 mb-16">
        {onClose && (
          <Button variant="contained" color="secondary" onClick={onClose}>
            Close
          </Button>
        )}
        {onRefresh && (
          <Button
            variant="contained"
            color="primary"
            className="ml-16"
            onClick={onRefresh}
          >
            Refresh
          </Button>
        )}
      </div>
    </div>
  );
}

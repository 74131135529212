import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AuthSelectors, SystemSelectors } from "../../../../state";
import { Button, Typography } from "@material-ui/core";
import {
  CheckboxInput,
  ToggleInput,
  SelectInput,
  AutocompleteInput,
} from "../../../../components/react-hook-form";
import {
  ChabadHouseTag,
  Program,
  School,
  StudentDetails,
} from "../../../../lib/apiRequests/ApiTypes";
import {
  getShliachCampuses,
  getStudentGroups,
} from "../../../../lib/apiRequests/ShliachApiRequests";
import { getPrograms } from "../../../../lib/apiRequests/ProgramsApiRequests";
import { Genders } from "../../../../lib";
import { WizardStepProps, WizardStepError } from "../WizardHelpers";
import { getFilteredStudents } from "../../../../lib/apiRequests/StudentsApiRequests";
import { getYearOptions } from "../../../../lib/utils";
import { WizardFooter } from "../WizardFooter";
import { Navigation } from "../../../../lib";
import Loader from "../../../../components/Loader";
import AppPages from "../../..";
import WizardError from "../WizardError";

const StudentViews = {
  Individuals: "Individuals",
  Filters: "Filters",
};

const gradYears = getYearOptions(2005, new Date().getFullYear() + 15).map(
  (yr) => ({
    value: yr,
    label: yr,
  }),
);

export const SelectRecipients = React.memo(function SelectRecipients(
  props: WizardStepProps,
) {
  const {
    next,
    onClose,
    recipients: { additionalStudents, filteredStudents },
    studentFilters,
    setAdditionalStudents,
    setStudentFilters,
    setFilteredStudents,
  } = props;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<WizardStepError>({});
  const [studentView, setStudentView] = useState(StudentViews.Individuals);
  const [submitPending, setSubmitPending] = useState(false);

  //lists for filters
  const [schools, setSchools] = useState<School[]>([]);
  const [studentGroups, setStudentGroups] = useState<ChabadHouseTag[]>([]);
  const [allStudents, setAllStudents] = useState<StudentDetails[]>([]);
  const [programs, setPrograms] = useState<Program[]>([]);

  const studentClasses = useSelector(SystemSelectors.studentClasses);
  const shliachId = useSelector(AuthSelectors.shliachID);

  const loadSchools = useCallback(async () => {
    const { error, data } = await getShliachCampuses(shliachId);
    if (error) {
      setErrors((errors) => ({ ...errors, dataRetrievalError: error }));
    } else {
      data && setSchools(data);
    }
  }, [shliachId]);

  const loadStudentGroups = useCallback(async () => {
    const { error, data } = await getStudentGroups();
    if (error) {
      setErrors((errors) => ({ ...errors, dataRetrievalError: error }));
    } else {
      data && setStudentGroups(data);
    }
  }, []);

  const loadPrograms = useCallback(async () => {
    const { error, data } = await getPrograms();
    if (error) {
      setErrors((errors) => ({ ...errors, dataRetrievalError: error }));
    } else {
      data && setPrograms(data);
    }
  }, []);

  const loadStudents = useCallback(async () => {
    const { error, data } = await getFilteredStudents({
      includeDetails: false,
      skipPaging: true,
    });
    if (error) {
      setErrors((errors) => ({ ...errors, dataRetrievalError: error }));
    } else {
      data && setAllStudents(data);
    }
  }, []);

  const loadData = useCallback(async () => {
    setLoading(true);
    const loadDataPromises = new Set<Promise<void>>();
    if (!allStudents.length) {
      loadDataPromises.add(loadStudents());
    }
    if (studentView === StudentViews.Filters) {
      if (!schools?.length) {
        loadDataPromises.add(loadSchools());
      }
      if (!studentGroups?.length) {
        loadDataPromises.add(loadStudentGroups());
      }
      if (!programs?.length) {
        loadDataPromises.add(loadPrograms());
      }
    }
    await Promise.all(loadDataPromises);
    setLoading(false);
  }, [
    allStudents.length,
    loadPrograms,
    loadSchools,
    loadStudentGroups,
    loadStudents,
    programs,
    schools,
    studentGroups,
    studentView,
  ]);

  useEffect(() => {
    loadData();
    // effect should run only on mount and when student view changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentView]);

  useEffect(() => {
    //when come back to the step set the tab correctly
    if (filteredStudents.length) {
      setStudentView(StudentViews.Filters);
    }
    //only want to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValid = useCallback(() => {
    if (
      studentView === StudentViews.Individuals &&
      !additionalStudents.length
    ) {
      setErrors((errors) => ({
        ...errors,
        validationError: "Please choose at least one student or use filters",
      }));
      return false;
    }
    return true;
  }, [additionalStudents.length, studentView]);

  const submitStep = useCallback(async () => {
    if (isValid()) {
      if (studentView === StudentViews.Filters) {
        setSubmitPending(true);
        //get filtered students from backend
        const { error, data } = await getFilteredStudents({
          ...studentFilters,
          includeDetails: true,
          skipPaging: true,
        });
        if (error) {
          setErrors((errros) => ({ ...errros, submissionError: error }));
          return;
        }
        data && setFilteredStudents(data);
        setSubmitPending(false);
      }
      next();
    }
  }, [studentFilters, isValid, next, setFilteredStudents, studentView]);

  const onRefresh = useCallback(() => {
    if (errors.dataRetrievalError) {
      setErrors((errors) => ({ ...errors, dataRetrievalError: undefined }));
    }
    loadData();
  }, [errors.dataRetrievalError, loadData]);

  return (
    <div className="page">
      {errors.dataRetrievalError ? (
        <WizardError
          errorMessage={errors.dataRetrievalError}
          onRefresh={onRefresh}
        />
      ) : loading ? (
        <Loader />
      ) : (
        <div>
          <div>
            <Typography variant="h6" className="mb-8">
              Select recipients
            </Typography>
            <Typography
              variant="body2"
              className="mb-12"
              style={{ color: "#747579" }}
            >
              You can also message students from a specific event or resource
              within managing the event or resource
            </Typography>

            <div>
              <div className="sms-wizard-container mt-24">
                <div className="flex flex-justify-space flex-align-center mobile-flex-column mobile-flex-align-top">
                  <div
                    className="select-recipient-input-div"
                    style={{ minWidth: 290 }}
                  >
                    <ToggleInput
                      name="recipientsAddByGroup"
                      options={[
                        {
                          id: StudentViews.Individuals,
                          name: "Add students",
                        },
                        {
                          id: StudentViews.Filters,
                          name: "Add by filters",
                        },
                      ]}
                      value={studentView}
                      onChange={(_: string, value: string) => {
                        setStudentView(value);
                        if (
                          errors.validationError &&
                          value === StudentViews.Filters
                        ) {
                          setErrors({
                            ...errors,
                            validationError: undefined,
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="text-right">
                    <CheckboxInput
                      label="Include archived"
                      checked={!!studentFilters.includeArchived}
                      onChange={() =>
                        setStudentFilters({
                          ...studentFilters,
                          includeArchived: !studentFilters.includeArchived,
                        })
                      }
                      name="includeArchived"
                    />
                  </div>
                </div>

                {studentView === StudentViews.Filters && (
                  <div>
                    <Typography variant="h6" className="mb-8 mt-24">
                      Create filters to choose recipients
                    </Typography>
                    <div className="sms-wizard-filter">
                      <div className="select-recipient-label-div">
                        <label className="accent-text select-recipient-label">
                          School
                        </label>
                      </div>
                      <div className="select-recipient-input-div half-width">
                        <SelectInput
                          placeholder="All..."
                          multiple={true}
                          options={schools}
                          name="campuses"
                          value={studentFilters.campusID}
                          onChange={(_, value) => {
                            setStudentFilters({
                              ...studentFilters,
                              campusID: value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="sms-wizard-filter">
                        <div className="select-recipient-label-div">
                          <label className="accent-text select-recipient-label">
                            By student group
                          </label>
                        </div>
                        <div className="select-recipient-input-div half-width">
                          <SelectInput
                            placeholder="All..."
                            multiple={true}
                            options={studentGroups.map((s, i) => ({
                              id: s.id,
                              name: s.tagName,
                            }))}
                            name={"tags"}
                            value={studentFilters.chabadHouseTags}
                            onChange={(_, value) => {
                              setStudentFilters({
                                ...studentFilters,
                                chabadHouseTags: value,
                              });
                            }}
                          />
                        </div>
                        <Button
                          className="link-text mt-8 ml-8"
                          variant="text"
                          onClick={() =>
                            Navigation.go(
                              AppPages.engagement.studentsGroups.path,
                            )
                          }
                        >
                          Manage groups
                        </Button>
                      </div>
                    </div>
                    <div className="checkbox-filter-div">
                      <label className="accent-text">Jewish</label>
                      <div>
                        <CheckboxInput
                          className="sms-wizard-checkbox"
                          label="Student Identifies as Jewish"
                          checked={!!studentFilters.isJewish}
                          onChange={() => {
                            setStudentFilters({
                              ...studentFilters,
                              isJewish: !studentFilters.isJewish,
                            });
                          }}
                          name="isJewish"
                        />
                      </div>
                    </div>
                    <div className="checkbox-filter-div mb-16">
                      <label className="accent-text">Gender</label>
                      <div className="flex">
                        <CheckboxInput
                          className="sms-wizard-checkbox"
                          label={Genders.Male}
                          checked={studentFilters.gender === Genders.Male}
                          onChange={(_, checked) => {
                            setStudentFilters({
                              ...studentFilters,
                              gender: checked ? Genders.Male : undefined,
                            });
                          }}
                          name={Genders.Male}
                        />
                        <CheckboxInput
                          className="sms-wizard-checkbox"
                          label={Genders.Female}
                          checked={studentFilters.gender === Genders.Female}
                          onChange={(_, checked) => {
                            setStudentFilters({
                              ...studentFilters,
                              gender: checked ? Genders.Female : undefined,
                            });
                          }}
                          name={Genders.Female}
                        />
                      </div>
                    </div>

                    <div className="sms-wizard-filter-base">
                      <div className="select-recipient-label-div">
                        <label className="accent-text select-recipient-label">
                          Grad year
                        </label>
                      </div>
                      <div className="select-recipient-input-div filter-width">
                        <SelectInput
                          placeholder="All..."
                          multiple={true}
                          options={gradYears.map((s, i) => ({
                            id: s.value,
                            name: s.label,
                          }))}
                          name={"gradYears"}
                          value={studentFilters.graduationYear}
                          onChange={(_, value) => {
                            setStudentFilters({
                              ...studentFilters,
                              graduationYear: value,
                            });
                          }}
                        />
                      </div>
                      <div
                        className="select-recipient-label-div"
                        style={{ marginLeft: 100 }}
                      >
                        <label className="accent-text select-recipient-label">
                          Demographic
                        </label>
                      </div>
                      <div className="select-recipient-input-div filter-width">
                        <SelectInput
                          placeholder="All..."
                          multiple={true}
                          options={studentClasses?.map((s: any) => ({
                            id: s.intValue,
                            name: s.displayValue,
                          }))}
                          name={"classes"}
                          value={studentFilters.classes}
                          onChange={(_, value) => {
                            setStudentFilters({
                              ...studentFilters,
                              classes: value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="sms-wizard-filter">
                      <div className="select-recipient-label-div">
                        <label className="accent-text select-recipient-label">
                          Participated / registered for
                        </label>
                      </div>
                      <div className="select-recipient-input-div filter-width">
                        <SelectInput
                          placeholder="Select..."
                          multiple={true}
                          options={programs?.map((p, i) => ({
                            id: p.programID,
                            name: p.name,
                          }))}
                          name={"programs"}
                          value={studentFilters.programsParticipated}
                          onChange={(_, value) => {
                            setStudentFilters({
                              ...studentFilters,
                              programsParticipated: value,
                            });
                          }}
                        />
                      </div>
                      <div
                        className="select-recipient-label-div"
                        style={{ marginLeft: 100 }}
                      >
                        <label className="accent-text select-recipient-label">
                          Exclude Participated in
                        </label>
                      </div>
                      <div className="select-recipient-input-div filter-width">
                        <SelectInput
                          placeholder="Select..."
                          multiple={true}
                          options={programs?.map((p, i) => ({
                            id: p.programID,
                            name: p.name,
                          }))}
                          value={studentFilters.excludeParticipatedInPrograms}
                          onChange={(_, value) => {
                            setStudentFilters({
                              ...studentFilters,
                              excludeParticipatedInPrograms: value,
                            });
                          }}
                          name={"excludePrograms"}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <Typography variant="h6" className="mb-8 mt-24">
                  {studentView === StudentViews.Filters
                    ? "Add additional individual students"
                    : "Students"}
                </Typography>
                {studentView === StudentViews.Individuals && (
                  <div className="mb-12 accent-text">
                    Please select recipients who will receive this SMS message.
                  </div>
                )}
                <AutocompleteInput
                  name={"students"}
                  multiple={true}
                  onChange={(_, val: number[]) => {
                    const students = allStudents.filter((s) =>
                      val.includes(s.studentID),
                    );
                    setAdditionalStudents(students);
                    if (errors.validationError && val.length) {
                      setErrors({
                        ...errors,
                        validationError: undefined,
                      });
                    }
                  }}
                  options={allStudents.map((s) => {
                    return {
                      id: s.studentID,
                      name: `${s.studentFirstName} ${s.studentLastName}`,
                    };
                  })}
                  value={additionalStudents.map((s) => s.studentID)}
                />
              </div>
            </div>
          </div>
          <div className="error-text mt-8">{errors.validationError}</div>
          <div className="error-text mt-16 flex flex-justify-end">
            {errors.submissionError}
          </div>
        </div>
      )}
      <WizardFooter
        onClose={onClose}
        next={submitStep}
        nextLoading={submitPending}
        nextDisabled={
          !!errors.dataRetrievalError ||
          !!errors.validationError ||
          submitPending
        }
      />
    </div>
  );
});

import React from "react";
import Select from "react-select";
import { Navigation, PageURL } from "../lib";

/**
 * For use with functional components, particularly the functional PaginatedTable.
 * Note the component simply displays and updates the results view count;
 * the data is reloaded external to the component.
 */
export default function TableResultsView({
  totalCount,
  increment,
  resultsView,
  getURL,
  name,
  page,
  pageRoute,
  setResultsView,
}) {
  const changeResultsView = (event) => {
    if (getURL || pageRoute) {
      if (name === "Contacts") {
        const url = PageURL.to(pageRoute.page, null, {
          ...pageRoute.query,
          p: 1,
          r: event.value,
        });
        Navigation.go(url);
      } else {
        Navigation.redirect(getURL(page, event.value));
      }
    } else {
      setResultsView && setResultsView(event.value);
    }
  };

  let options = Array(3)
    .fill()
    .map((_, i) => ++i * increment)
    .map((resultSize) => ({ value: resultSize, label: resultSize }));
  return (
    totalCount > 0 && (
      <div
        className="flex flex-align-center mobile-flex-justify-end"
        style={{ minWidth: 100 }}
      >
        <p className="flex flex-align-center mr-8">View </p>
        <Select
          className={"view-select"}
          classNamePrefix={"view-select"}
          defaultValue={{
            value: resultsView,
            label: resultsView,
          }}
          options={options}
          onChange={changeResultsView}
        />
      </div>
    )
  );
}

import React from "react";
import { useWatch } from "react-hook-form";
import { Genders } from "../../../../../lib";
import { Tooltip, Typography } from "@material-ui/core";
import { ProfileFormSectionProps } from "../../ProfileFormHelpers";
import {
  ShliachContactInfo,
  ShliachGeneralInfo,
  ShliachShlichusInfo,
} from "./";
import { ProfileFormInfoIcon } from "../../ProfileForm.styles";
import { getProfileSectionId, ProfileSectionWrapper } from "../../profileMenu";

export interface ShliachInfoProps extends ProfileFormSectionProps {
  personType: "person" | "spouse";
}

export const ShliachInfo = React.memo(
  /**
   *
   */
  function ShliachInfo(props: ShliachInfoProps) {
    const { personType, intersectionOptions } = props;

    const [shliachId, gender] = useWatch({
      name: [`${personType}.id`, `${personType}.gender`],
    });

    const shliachDescription =
      gender === Genders.Female ? "Shlucha" : "Shliach";
    const shliachDisabled = !shliachId;

    return (
      <ProfileSectionWrapper
        id={getProfileSectionId(personType)}
        intersectionOptions={intersectionOptions}
      >
        <Typography variant="h6">
          {shliachDescription} information
          {shliachDisabled && (
            <Tooltip
              title={
                <>
                  To add a spouse contact{" "}
                  <a
                    href="mailto:help@chabadoncampus.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    help@chabadoncampus.org
                  </a>
                </>
              }
            >
              <ProfileFormInfoIcon />
            </Tooltip>
          )}
        </Typography>
        <ShliachGeneralInfo {...props} shliachDisabled={shliachDisabled} />
        {!shliachDisabled && (
          <>
            <ShliachContactInfo
              {...props}
              shliachDescription={shliachDescription}
            />
            <ShliachShlichusInfo {...props} />
          </>
        )}
      </ProfileSectionWrapper>
    );
  },
);

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { AccountActions, SmsActions } from "../../state";
import SmsAddCredit from "./SmsAddCredit";

export default connect(
  (state) => {
    return { account: state.account, auth: state.auth, ...state.sms };
  },
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {
          getAccountInfo: AccountActions.getAccountInfo,
          submitSmsCredit: SmsActions.submitSmsCredit,
        },
        dispatch,
      ),
    };
  },
)(SmsAddCredit);

import React, { useState, useMemo, useEffect, useCallback, memo } from "react";
import { useSelector } from "react-redux";
// /* @ts-ignore */
// import Modal from "react-modal";
import { getCociLogo, isMobileView, PageLink, PageNavLink } from "../lib";
import Pages from "../pages";
import Banners from "./Banners";
import DynamicProgramsMenu from "./DynamicProgramsMenu";
import DynamicDirectoryMenu from "./DynamicDirectoryMenu";
import DynamicEngagementMenu from "./DynamicEngagementMenu";
import SystemShortcutsMenu from "./SystemShortcutsMenu";
import {
  // AuthActions,
  AuthSelectors,
  SystemSelectors,
  useAction,
} from "../state";
import { ProgramsActions } from "../state/programs/actions";
import MobileMenu from "./mobile/MobileMenu";
import { AppState, GenericObject } from "../state/types";
import ProfileDropdown from "./ProfileDropdown";
import Bh from "./Bh";

interface DefaultAppBarProps {
  children: React.ReactNode;
  props?: GenericObject;
}

function DefaultAppBar({ children, props = {} }: DefaultAppBarProps) {
  const getProgramRoutes = useAction(ProgramsActions.getProgramRoutes);
  // const doSignout = useAction(AuthActions.doSignout);

  const banners = useSelector(SystemSelectors.banners);
  const { routes } = useSelector((state: AppState) => state.programs);
  const {
    //incompletePersonProfile,
    person: user,
  } = useSelector((state: AppState) => state.auth);

  const { isEnrolledInMessaging } = useSelector(AuthSelectors.engagementAccess);

  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [showShadow, setShowShadow] = useState(false);

  const isPhone = isMobileView();

  // const allowedPagesForUserWithIncompleteProfile = useMemo(
  //   () => [
  //     Pages.account.login.path,
  //     Pages.account.resetPass.path,
  //     //TODO: take out when delete old profile
  //     // Pages.profile.personal.path,
  //     // Pages.profile.chabadHouse.path,
  //     // Pages.profile.school.path,
  //   ],
  //   [],
  // );

  useEffect(() => {
    getProgramRoutes();
  }, [getProgramRoutes]);

  const toggleDropdown = useCallback(() => {
    setAccountDropdownOpen(!accountDropdownOpen);
  }, [accountDropdownOpen]);

  // const allowPageAccessForUserWithIncompleteProfile = useCallback(
  //   () => allowedPagesForUserWithIncompleteProfile.includes(props.path),
  //   [allowedPagesForUserWithIncompleteProfile, props.path],
  // );

  useEffect(() => {
    const scrollHandler = () => setShowShadow(window.pageYOffset > 10);
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  const headerClassName = useMemo(
    () => `header ${showShadow ? "shadow" : ""}`,
    [showShadow],
  );

  const pageWrapperClassName = useMemo(
    () => (!isPhone ? "page-wrapper" : "page-wrapper page-wrapper-mobile"),
    [isPhone],
  );

  // const showCompleteProfileModal = useMemo(
  //   () =>
  //     user &&
  //     incompletePersonProfile &&
  //     incompletePersonProfile.length > 0 &&
  //     !allowPageAccessForUserWithIncompleteProfile(),
  //   [
  //     user,
  //     incompletePersonProfile,
  //     allowPageAccessForUserWithIncompleteProfile,
  //   ],
  // );

  return (
    <>
      <Banners banners={banners} isPhone={isPhone} />
      <header className={headerClassName}>
        <div className="container">
          <PageLink to={"/"} className="mobile-hidden">
            <img src={getCociLogo()} alt="logo" className="mobile-hidden" />
          </PageLink>
          <div className="desktop-hidden tablet-hidden">
            <MobileMenu routes={routes} />
          </div>
          <nav className="mobile-hidden">
            <ul className="nav-links">
              <li className="nav-link">
                <PageNavLink
                  to={"/"}
                  exact={true}
                  activeClassName="active-nav-link"
                >
                  Home
                </PageNavLink>
              </li>
              <li className="nav-link program-dropdown-container">
                <PageNavLink
                  to={Pages.directory.directory}
                  activeClassName="active-nav-link"
                >
                  Directory
                </PageNavLink>
                <DynamicDirectoryMenu />
              </li>

              <li className="nav-link program-dropdown-container">
                <PageNavLink to="/programs" activeClassName="active-nav-link">
                  Programs
                </PageNavLink>
                <DynamicProgramsMenu routes={routes} />
              </li>
              <li className="nav-link program-dropdown-container">
                <PageNavLink
                  to={Pages.engagement.engagement}
                  activeClassName="active-nav-link"
                >
                  Engagement
                </PageNavLink>
                <DynamicEngagementMenu />
              </li>
              <li className="nav-link">
                <PageNavLink
                  to={"/updates"}
                  className="refresh"
                  activeClassName="active-nav-link"
                >
                  Updates
                </PageNavLink>
              </li>
              <li className="nav-link flex flex-align-center">
                <div className="program-dropdown-container shortcut-link">
                  <SystemShortcutsMenu />
                </div>
              </li>
            </ul>
          </nav>
          <div className="header-right">
            <ProfileDropdown
              accountDropdownOpen={accountDropdownOpen}
              overrideProfileImageURL={user?.profileImageURL}
              showAccountLink
              showSendSmsIcon={isEnrolledInMessaging}
              toggleDropdown={toggleDropdown}
            />
            <Bh />
          </div>
        </div>
      </header>
      <div className={pageWrapperClassName}>
        {children}

        {/* //TODO: Move modal to its own component */}
        {/* disable  this feature for now*/}
        {/* {showCompleteProfileModal && (
          <Modal className="modal-container" isOpen={true}>
            <div className="modal-card card small-modal">
              <p className="fw-700 medium-text mb-16">
                Hi {user.firstName}! Let's complete your profile
              </p>
              <p className="line-height-double">
                We need your updated information so that we can service you
                better. You are missing some{" "}
                {incompletePersonProfile &&
                  (incompletePersonProfile.length < 3
                    ? incompletePersonProfile.join(" and ")
                    : [
                        incompletePersonProfile.slice(0, -1).join(", "),
                        incompletePersonProfile.slice(-1),
                      ].join(" and "))}{" "}
                profile information. Please complete this information in order
                to proceed.
              </p>
              <div className="modal-btns">
                <PageLink
                  to={Pages.profile.profile}
                  className="link-text uppercase-text"
                >
                  My Profile
                </PageLink>
                <p
                  className="link-text ml-24 uppercase-text"
                  onClick={() => doSignout()}
                >
                  Sign Out
                </p>
              </div>
            </div>
          </Modal>
        )} */}
      </div>
    </>
  );
}

export default memo(DefaultAppBar);

import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Billing from "./billing/BillingContainer";
import Courses from "./courses/CoursesContainer";
import Settings from "./settings/SettingsContainer";
import Students from "./students/StudentsContainer";
import { EduPageViews } from "../constants";
import {
  InternPermissions,
  Navigation,
  PageNavLink,
  PageURL,
  doArraysIntersect,
} from "../../../lib";
import { AuthSelectors } from "../../../state";
import {
  getJewishUMembershipEffectiveThroughYear,
  jewishUMembershipExpirationDate,
} from "../eduUtils";

const myEduPageViews = {
  [EduPageViews.courses]: {
    component: Courses,
    externalUserAuthorization: [
      InternPermissions.eduAttendance,
      InternPermissions.eduSchedule,
      InternPermissions.eduStudents,
    ],
  },
  [EduPageViews.students]: {
    component: Students,
    externalUserAuthorization: [InternPermissions.eduStudents],
  },
  // TODO COC-4612: Temporarily hiding billing page
  // [EduPageViews.billing]: {
  //   component: Billing,
  //   externalUserAuthorization: [],
  // },
  [EduPageViews.settings]: {
    component: Settings,
    externalUserAuthorization: [],
  },
};

function MyEdu(props) {
  const {
    enrollment: { data: enrollment },
    pageRoute: {
      page,
      params: { pageView, ...params },
    },
  } = props;

  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const userClaims = useSelector(AuthSelectors.claims);

  const redirectToPageView = useCallback(
    (pageViewRedirect) => {
      Navigation.redirect(
        PageURL.to(
          page,
          {
            ...params,
            pageView: pageViewRedirect,
          },
          null,
        ),
      );
    },
    [page, params],
  );

  useEffect(() => {
    // if enrollment is expired, redirect to the billing view
    if (enrollment.isExpired && pageView !== EduPageViews.billing) {
      redirectToPageView(EduPageViews.billing);
    }
    // if there is a missing/invalid pageView, redirect to the courses view
    else if (!pageView || !myEduPageViews[pageView]) {
      redirectToPageView(EduPageViews.courses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollment.isExpired, pageView]);

  // verify non-shliach is authorized for the selected pageView. otherwise, redirect
  useEffect(() => {
    if (pageView && !isShliach) {
      const isAuthorizedForPageView = doArraysIntersect(
        userClaims,
        myEduPageViews[pageView].externalUserAuthorization,
      );
      if (!isAuthorizedForPageView) {
        Navigation.redirect("/404");
      }
    }
  }, [pageView, isShliach, userClaims]);

  const membershipExpirationBanner = useMemo(() => {
    if (!isShliach) return null; // non-shliach users do not currently have access to billing

    const {
      eduProgramSubscriptionFee,
      hasFailedBillingCharge,
      isExpired,
      isExpiringSoon,
    } = enrollment || {};

    if (!isExpired && !isExpiringSoon) return null;

    const membershipEffectiveThroughYear =
      getJewishUMembershipEffectiveThroughYear();

    const membershipRenewalDisabled = hasFailedBillingCharge;
    const hideNavLink =
      membershipRenewalDisabled && pageView === EduPageViews.billing;

    return (
      <div className={`edu-expiration-banner${isExpired ? " expired" : ""}`}>
        <div>
          <i className="material-icons mr-12">
            {isExpired ? "error" : "warning"}
          </i>
          Your annual membership{" "}
          {isExpired
            ? "has expired"
            : `will expire on ${jewishUMembershipExpirationDate}`}
          . Please renew your membership for ${eduProgramSubscriptionFee} to
          continue offering courses for {membershipEffectiveThroughYear - 1}-
          {membershipEffectiveThroughYear}.
        </div>
        {!hideNavLink && (
          <PageNavLink
            to={page}
            params={{
              pageView: EduPageViews.billing,
              programScheduleId: params.programScheduleId,
            }}
            query={membershipRenewalDisabled ? null : { renew: true }}
          >
            {membershipRenewalDisabled ? "View billing" : "Renew"}
          </PageNavLink>
        )}
      </div>
    );
  }, [enrollment, isShliach, page, pageView, params.programScheduleId]);

  // if enrollment is expired, return null until the user is ridirected to the billing pageView
  if (enrollment.isExpired && pageView !== EduPageViews.billing) return null;

  const myEduPage = myEduPageViews[pageView]?.component;
  if (!myEduPage) return null;

  return (
    <>
      {membershipExpirationBanner}
      {React.createElement(myEduPage, props)}
    </>
  );
}

export default memo(MyEdu);

import { createSelector } from "@reduxjs/toolkit";
import { JewishInterestLevels } from "../engagement/types";

const newArray = [];

export const SystemSelectors = {
  allSysLists(state) {
    return state.sys;
  },
  banners(state) {
    return state.sys.portalBanners;
  },
  chabadHousePersonnelPositions(state) {
    return state.sys.chabadHousePersonnelPositions;
  },
  countries(state) {
    return state.sys.countries || newArray;
  },
  customQuestionResponseTypes(state) {
    return state.sys.customQuestionResponseTypes;
  },
  demographics(state) {
    return state.sys.demographics || newArray;
  },
  distances(state) {
    return state.sys.distances || newArray;
  },
  engagementKpis(state) {
    return state.sys.engagementKpis;
  },
  grantStatuses(state) {
    return state.sys.grantStatuses || newArray;
  },
  interactionTypes(state) {
    return state.sys.interactionTypes;
  },
  interactionProgramTypes(state) {
    return state.sys.interactionProgramTypes;
  },
  interactionCategories(state) {
    return state.sys.interactionCategories;
  },
  internPrograms(state) {
    return state.sys.internPrograms;
  },
  engagementJewishInterestLevels: createSelector(
    (state) => state.sys.jewishInterestLevels || newArray,
    (jewishInterestLevels) =>
      jewishInterestLevels.filter(
        (jil) => jil.enumValue !== JewishInterestLevels.Other,
      ),
  ),
  jewishInterestLevels(state) {
    return state.sys.jewishInterestLevels || newArray;
  },
  portalRedirects(state) {
    return state.sys.portalRedirects || state.sys.publicPortalRedirects; // fall back on publicPortalRedirects if portalRedirects are not present
  },
  programLevels(state) {
    return state.sys.programLevels || newArray;
  },
  regions(state) {
    return state.sys.regions || newArray;
  },
  schoolTypes(state) {
    return state.sys.schoolTypes || newArray;
  },
  prestigeTypes(state) {
    return state.sys.prestigeTypes || newArray;
  },
  shluchimContactMethodTypes(state) {
    return state.sys.shluchimContactMethodTypes || newArray;
  },
  sortedInteractionCategories(state) {
    return state.sys.interactionCategories?.sort(
      (c1, c2) => c2.rating - c1.rating,
    );
  },
  studentClasses(state) {
    return state.sys.studentClasses || newArray;
  },
  studentResources(state) {
    return state.sys.studentResources || newArray;
  },
  studyAreas(state) {
    return state.sys.studyAreas || newArray;
  },
  titles(state) {
    return state.sys.titles || newArray;
  },
  tripStudentRecommendationStatuses(state) {
    return state.sys.tripStudentRecommendationStatuses || newArray;
  },
  tripStudentAcquaintanceLevels(state) {
    return state.sys.tripStudentAcquaintanceLevels || newArray;
  },
  youthAndAdultTShirtSizes(state) {
    return state.sys.youthAndAdultTShirtSizes || newArray;
  },
};

import ApiCallErrorMessageHandler from "../ApiCallErrorMessageHandler";
import { AuthRequest } from "../AuthRequest";
import { BaseResponse, Program } from "./ApiTypes";

export async function getPrograms() {
  const programsResponse: BaseResponse<Program[]> = {};
  try {
    const response = await AuthRequest.get("Programs");
    programsResponse.data = response.data.payload;
  } catch (err) {
    programsResponse.error = ApiCallErrorMessageHandler(err);
  }
  return programsResponse;
}

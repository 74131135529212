import { Box, InputLabel, Typography } from "@material-ui/core";
import React, { forwardRef } from "react";
import { FileForUpload } from "../../../lib/types";

export type FileUploadInputProps = {
  accept?: string;
  error?: any;
  label?: string;
  image?: boolean;
  name: string;
  onChange: (...event: any[]) => void;
  onValueChange?: (value: string | FileForUpload) => void;
  uploadComponent: (value: string | FileForUpload) => React.ReactNode;
  value: any;
};

export const FileUploadInput = React.memo(
  forwardRef(
    /**
     *
     */
    function FileUploadInput(
      {
        accept,
        error,
        image,
        name,
        label,
        onChange,
        value,
        onValueChange,
        uploadComponent,
      }: FileUploadInputProps,
      ref: React.Ref<any> | null,
    ) {
      const onSelectFile = async (
        event: React.ChangeEvent<HTMLInputElement>,
      ) => {
        if (event.target.files?.length) {
          const file = event.target.files[0];

          const value = {
            file,
            previewUrl: URL.createObjectURL(file),
          };

          onChange({ target: { name, value } });
          onValueChange && onValueChange(value);
        }
      };
      return (
        <Box component="label">
          {label && (
            <InputLabel error={error} htmlFor={name}>
              {label}
            </InputLabel>
          )}
          {uploadComponent(value)}
          <input
            type="file"
            accept={image ? "image/*" : accept}
            capture={image}
            onChange={onSelectFile}
            style={{
              clip: "rect(0 0 0 0)",
              clipPath: "inset(50%)",
              height: 1,
              overflow: "hidden",
              position: "absolute",
              bottom: 0,
              left: 0,
              whiteSpace: "nowrap",
              width: 1,
            }}
          />
          {error && (
            <Typography
              variant="caption"
              style={{
                color: "#e94746",
                display: "inline-block",
                marginTop: "8px",
              }}
            >
              {error.message}
            </Typography>
          )}
        </Box>
      );
    },
  ),
);

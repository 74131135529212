import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Typography } from "@material-ui/core";
import {
  BillingHeaderCardStyled,
  BillingStatusChipStyled,
} from "./Billing.styles";
import BillingModal, { BillingReasons } from "./BillingModal";
import { EduBillingCharges, EduEnrollment } from "./BillingTypes";
import { AccountSelectors } from "../../../../state";
import { formatCurrency } from "../../../../lib";
import { useMobile } from "../../../../themes";
import moment from "moment";

interface CreditsBillingStatusProps {
  billingCharges: EduBillingCharges | undefined;
  billingChargesError: boolean;
  enrollment: EduEnrollment;
  refreshBillingData: () => void;
}

function CreditsBillingStatus({
  billingCharges,
  billingChargesError,
  enrollment,
  refreshBillingData,
}: CreditsBillingStatusProps) {
  const isMobile = useMobile("sm");

  const credCardInfo = useSelector(AccountSelectors.credCardInfo);

  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);

  const {
    lastChargeAmount = 0,
    lastChargeDate,
    nextChargeDate,
  } = billingCharges || {};

  // if billingCharges has not yet loaded, fall back on enrollment.hasFailedBillingCharge to immediately display the failure status
  const didLastChargeFail = billingCharges
    ? billingCharges.didLastChargeFail
    : enrollment.hasFailedBillingCharge;

  return (
    <>
      <BillingHeaderCardStyled
        className={`payment${didLastChargeFail ? " error" : ""}`}
      >
        <Box>
          <Box className="flex flex-align-center mobile-block">
            <Typography variant="h6">Credits billing</Typography>
            {didLastChargeFail && (
              <BillingStatusChipStyled className="error">
                Failed
              </BillingStatusChipStyled>
            )}
          </Box>
          <Button
            color={didLastChargeFail ? "default" : "primary"}
            onClick={() => setShowPaymentMethodModal(true)}
          >
            {!credCardInfo
              ? `Add${isMobile ? "" : " card"}`
              : didLastChargeFail
              ? `Update${isMobile ? "" : " card"}`
              : "Edit"}
          </Button>
        </Box>
        <Typography variant="body1">
          {credCardInfo
            ? `${isMobile ? "" : "Payment method: "}${
                credCardInfo.cardBrand
              } ending in ${credCardInfo.lastFour} · Expires ${
                credCardInfo.expMonth
              }/${credCardInfo.expYear}`
            : "No card on file"}
        </Typography>
        <Typography
          className="mt-4"
          variant="caption"
          color={billingChargesError ? "error" : "textSecondary"}
        >
          {billingChargesError ? (
            "Unable to retrieve charge details. Refresh the page to try again."
          ) : billingCharges ? (
            <>
              {lastChargeDate
                ? `Last charge of $${formatCurrency(lastChargeAmount)} ${
                    didLastChargeFail ? "failed" : "was billed"
                  } on ${moment(lastChargeDate).format("M/D/YY")} · `
                : ""}
              Next charge for{" "}
              {didLastChargeFail
                ? `$${formatCurrency(lastChargeAmount)} + `
                : ""}
              this month's accrued cost will be billed on{" "}
              {moment(nextChargeDate).format("M/D/YY")}
            </>
          ) : (
            ""
          )}
        </Typography>
      </BillingHeaderCardStyled>
      {showPaymentMethodModal && (
        <BillingModal
          billingAmount={didLastChargeFail ? lastChargeAmount : 0}
          billingReason={BillingReasons.PaymentMethodUpdate}
          enrollmentId={enrollment.id}
          onClose={(refresh) => {
            setShowPaymentMethodModal(false);
            if (refresh) refreshBillingData();
          }}
        />
      )}
    </>
  );
}

export default memo(CreditsBillingStatus);

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  RaffleSelectors,
  RaffleActions,
} from "../../../state";
import ConfirmationModal from "../../../components/ConfirmationModal";
import TeamModal from "./TeamModal";

export default function Teams(props) {
  const dispatch = useDispatch();
  //STATE
  const [selectedTeam, setSelectedTeam] = useState();
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  //SELECTORS
  const donorSiteURL = useSelector(RaffleSelectors.donorSiteURL);
  const teamSales = useSelector(RaffleSelectors.teamSales);

  const { raffle } = props;
  const {
    enrollmentDetails: { id: sellerEnrollmentID },
    referrers: teams,
  } = raffle;

  const copyToClipboard = (slug) => {
    navigator.clipboard.writeText(getTeamURL(slug));
  };

  const getTeamURL = (slug) => donorSiteURL + "/" + slug;

  const onClose = () => {
    setSelectedTeam();
    setShowTeamModal(false);
  };

  const teamHasOrders = (teamId) => {
    return teamSales.find((t) => t.referrerID === teamId)?.salesByReferrer > 0;
  };

  return (
    <div className="raffles-form-section">
      <p className="large-text fw-900 mb-8">Teams</p>
      <p className="accent-text-dark mb-8 line-height-double">
        Create teams to assist you in selling tickets. Each team will have its
        own URL and goal. Teams will be notified via email when a purchase is
        made on their page.
      </p>
      <p className="accent-text small-text mb-16">
        Note: Team goals are open to the public.
      </p>
      <div>
        <div
          className="flex flex-align-center link-text uppercase-text btn btn-accent mr-8 fit-width"
          onClick={() => {
            setSelectedTeam();
            setShowTeamModal(true);
          }}
        >
          <i className="material-icons mr-4">add</i>
          Create a new team
        </div>
        {!!teams.length && (
          <div className="teams-separator">
            <p className="fw-700 mb-16">My Teams</p>

            <div className="teams-grid-row">
              {teams.map((team, index) => (
                <div
                  className="hover-delete-container flex flex-align-center full-width"
                  key={index}
                >
                  <div className="team-section">
                    <p className="medium-text fw-700 mb-16">{team.name}</p>
                    <div className="flex flex-align-center flex-justify-space">
                      <div className="flex flex-align-center">
                        <a
                          className="link-text flex flex-align-center"
                          href={getTeamURL(team.slug)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p className="link-text uppercase-text">Team Page</p>
                          <i className="material-icons large-text ml-8">
                            open_in_new
                          </i>
                        </a>
                        <p>
                          <i
                            className="material-icons link-text large-text flex flex-align-center ml-8"
                            onClick={() => copyToClipboard(team.slug)}
                          >
                            content_copy
                          </i>
                        </p>
                      </div>
                      <div>
                        <div className="flex flex-align-center">
                          <i
                            className="material-icons large-text link-text"
                            onClick={() => {
                              setSelectedTeam(team);
                              setShowTeamModal(true);
                            }}
                          >
                            edit
                          </i>
                          <div className="tooltip-container tooltip-height">
                            <i
                              className={`material-icons large-text link-text ml-8 
                                ${!!teamHasOrders(team.id) && "disabled"}`}
                              onClick={() => {
                                if (!!teamHasOrders(team.id)) return;
                                setSelectedTeam(team);
                                setShowDeleteTeamModal(true);
                              }}
                            >
                              delete
                            </i>
                            {!!teamHasOrders(team.id) && (
                              <span className="tooltip">
                                This team cannot be deleted since there are
                                orders associated with it.
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {showTeamModal && (
          <TeamModal
            close={onClose}
            teamForEdit={selectedTeam}
            teams={teams}
            sellerEnrollmentId={sellerEnrollmentID}
            donorSiteURL={donorSiteURL}
          />
        )}
        {showDeleteTeamModal && (
          <ConfirmationModal
            cancel={() => {
              setSelectedTeam();
              setShowDeleteTeamModal(false);
            }}
            confirm={async () => {
              setErrorMessage();
              const response = await dispatch(
                RaffleActions.deleteRaffleTeam(
                  selectedTeam.id,
                  sellerEnrollmentID,
                ),
              );
              if (!response.success) {
                setErrorMessage(
                  response.errorMessage ||
                    "Something went wrong and your team could not be deleted. Please try again.",
                );
              } else {
                setSelectedTeam();
                setShowDeleteTeamModal(false);
              }
            }}
            message={`Are you sure you'd like to delete Team ${selectedTeam.name}?`}
            show={showDeleteTeamModal}
            title="Delete Team"
            confirmText="Delete"
            errorMessage={errorMessage}
          />
        )}
      </div>
    </div>
  );
}

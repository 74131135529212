import ApiCallErrorMessageHandler from "../ApiCallErrorMessageHandler";
import { AuthRequest } from "../AuthRequest";
import { BaseResponse, StudentDetails, StudentFilters } from "./ApiTypes";

export async function getFilteredStudents(filters: StudentFilters) {
  const studentsResponse: BaseResponse<StudentDetails[]> = {};
  try {
    const response = await AuthRequest.get(`Students/byShliach`, {
      params: filters,
    });
    studentsResponse.data = response.data.payload.results;
  } catch (err) {
    studentsResponse.error = ApiCallErrorMessageHandler(err);
  }
  return studentsResponse;
}

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RaffleSelectors, RaffleActions } from "../../../state";
import {
  getStudents,
  getStudentBasicData,
} from "../../../state/raffle/requests";

import _isEqual from "lodash.isequal";

import Modal from "../../../components/Modal";
import PhoneInput from "../../../components/form/PhoneInput";
import CustomSelect from "../../../components/form/CustomSelect";
import ValidatedInput from "../../../components/form/ValidatedInput";
import { validateEmail } from "../../../lib";

export default function TeamModal(props) {
  const {
    teamForEdit = {},
    teams,
    close,
    sellerEnrollmentId,
    donorSiteURL,
  } = props;

  //STATE
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [validation, setValidation] = useState({});
  const [students, setStudents] = useState([]);
  const [team, setTeam] = useState({
    sellerEnrollmentId,
    id: teamForEdit.id || "",
    studentID: teamForEdit.studentID || "",
    name: teamForEdit.name || "",
    slug: teamForEdit.slug || "",
    email: teamForEdit.email || "",
    phone: teamForEdit.phone || "",
    phoneCountryID: teamForEdit.phoneCountryID || "",
    goal: teamForEdit.goal || "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  //SELECTORS
  const chabadHouseID = useSelector(RaffleSelectors.chabadHouseID);

  //ACTIONS
  const submitRaffleTeam = RaffleActions.submitRaffleTeam;

  const dispatch = useDispatch();

  const onSubmit = async () => {
    setSubmitAttempted(true);
    if (!isValid()) return;
    setLoading(true);
    setErrorMessage();
    const teamToSave = { ...team, sellerEnrollmentId };
    const response = await dispatch(submitRaffleTeam(teamToSave));
    setLoading(false);
    if (!response.success) {
      setErrorMessage(
        response.errorMessage ||
          "Something went wrong and your team could not be updated. Please try again.",
      );
    } else {
      close();
    }
  };

  const fullName = (student) => {
    return student.firstName + " " + student.lastName;
  };

  const studentsOptions = students
    .map((s) => {
      return {
        label: fullName(s),
        value: s.id,
      };
    })
    .filter((s) => !teams.find((t) => t.studentID === s.value));

  const onChangePhoneInput = (phone, phoneCountryID) => {
    setTeam({ ...team, phone, phoneCountryID });
  };

  const onChangeTeam = (name, value) => {
    setTeam({ ...team, [name]: value });
  };

  const onChangeStudent = async (name, value) => {
    if (value) {
      //set student fields when student is selected
      const selectedStudent = students.find((s) => s.id === value);
      const studentName = fullName(selectedStudent);
      const slug = createSlug(studentName);

      const { data, success } = await getStudentBasicData(value);

      setTeam({
        ...team,
        studentID: value,
        name: !isTeamDataTaken("name", studentName) ? studentName : "",
        slug: !isTeamDataTaken("slug", slug) ? slug : "",
        email: success && data.email ? data.email : "",
        phone: success && data.cell ? data.cell : "",
        phoneCountryID: success && data.cellCountryID ? data.cellCountryID : "",
      });
    } else {
      //clear student fields when student is unselected
      setTeam({
        ...team,
        studentID: "",
        name: "",
        slug: "",
        email: "",
        phone: "",
        phoneCountryID: "",
      });
    }
  };

  const onChangeValidation = (name, isValid) => {
    setValidation({ ...validation, [name]: isValid });
  };

  const onCancel = useCallback(() => {
    close();
  }, [close]);

  const isValid = () => {
    return (
      team.name &&
      team.slug &&
      team.email &&
      !validation.name &&
      !validation.slug &&
      !validation.email &&
      !validation.phone &&
      !validation.goal
    );
  };

  const createSlug = (value) => {
    return value.trim().replaceAll(" ", "-").toLowerCase();
  };

  const isTeamDataTaken = useCallback(
    (name, value) => {
      return teams.some(
        //when editing existing team, allow team to have same values as before
        //so matching value is invalid if it's a new team or if not same team
        (t) =>
          (!team.id || t.id !== team.id) &&
          t[name].toLowerCase().trim() === value.toLowerCase().trim(),
      );
    },
    [teams, team],
  );

  useEffect(() => {
    (async () => {
      const { list } = await getStudents(chabadHouseID);
      setStudents(list);
    })();
  }, [chabadHouseID]);

  return (
    <Modal show={true}>
      <div className="modal-container full-page-mobile-modal-container team-modal-container">
        <div className="modal-card card team-modal relative">
          <p className="xl-text fw-900 mb-24 mobile-mt-24">
            {team.id ? "Edit Team" : "Create a Team"}
          </p>
          {!team.id && !!students.length && (
            <>
              <p className="accent-text-dark mb-16">
                Select a student (optional)
              </p>
              <div className="flex flex-align-center mb-24 mobile-block">
                <CustomSelect
                  name="studentID"
                  className="custom-select"
                  classNamePrefix="custom-select"
                  isSearchable={true}
                  menuPlacement={"bottom"}
                  placeholder={"Select student..."}
                  isClearable={true}
                  onChange={onChangeStudent}
                  options={studentsOptions}
                  value={team.studentID}
                />
              </div>
              <hr className="team-modal-separator smaller-margin" />
            </>
          )}
          <div className="flex flex-align-center mb-16 mobile-block">
            <label className="accent-text-dark">Team Name</label>
            <ValidatedInput
              input={<input type="text" className="custom-input flex" />}
              name="name"
              onChange={(e) => onChangeTeam(e.target.name, e.target.value)}
              required={true}
              showRequired={submitAttempted}
              value={team.name}
              showValidation={
                (submitAttempted && !team.name) || !!validation.name
              }
              validate={(value) => {
                const isValid = !isTeamDataTaken("name", value);
                onChangeValidation("name", !isValid);
                return isValid;
              }}
              onBlur={(_, value) => {
                const slug = createSlug(value);
                if (!isTeamDataTaken("slug", slug)) {
                  onChangeTeam("slug", slug);
                }
              }}
              validationMessage="Team name already taken"
            />
          </div>
          <div className="flex flex-align-center mobile-block">
            <label className="accent-text-dark">Team URL</label>
            <div className="flex flex-column full-width">
              <ValidatedInput
                input={<input type="text" className="custom-input flex mb-4" />}
                name="slug"
                onChange={(e) => onChangeTeam(e.target.name, e.target.value)}
                required={true}
                showRequired={submitAttempted}
                showValidation={
                  (submitAttempted && !team.slug) || !!validation.slug
                }
                value={team.slug}
                validate={(value) => {
                  // do onChange here instead of onBlur since
                  // validate needs to happen once slug is created
                  const slug = createSlug(value);
                  onChangeTeam("slug", slug);
                  const isValid = !isTeamDataTaken("slug", slug);
                  onChangeValidation("slug", !isValid);
                  return isValid;
                }}
                validationMessage="Team URL already taken"
              />
            </div>
          </div>

          <div className="flex flex-align-center mb-16 mobile-block">
            {!validation.slug && (
              <p className="flex flex-align-center small-text accent-text additional-text">
                {donorSiteURL}/{team.slug}
              </p>
            )}
          </div>
          <div className="flex flex-align-center mobile-block">
            <label className="accent-text-dark">Notification Email</label>
            <ValidatedInput
              input={
                <input
                  type="text"
                  className={`custom-input flex ${
                    (submitAttempted && !team.email) || !!validation.email
                      ? "error"
                      : ""
                  }`}
                />
              }
              name="email"
              onChange={(e) => onChangeTeam(e.target.name, e.target.value)}
              required={true}
              showRequired={submitAttempted}
              showValidation={
                (submitAttempted && !team.email) || !!validation.email
              }
              validate={(emailToValidate) => {
                const isValid = validateEmail(emailToValidate);
                onChangeValidation("email", !isValid);
                return isValid;
              }}
              validationMessage="Invalid email address"
              value={team.email}
            />
          </div>
          <div className="flex flex-align-center mb-16 mt-4 mobile-block small-text accent-text additional-text">
            Notifications will be sent to this email address for all donations.
          </div>
          <div className="flex flex-align-center mb-16 mobile-block">
            <label className="accent-text-dark">Phone</label>
            <PhoneInput
              className="full-width phone-input"
              name="phone"
              countryId={team.phoneCountryID}
              onChange={onChangePhoneInput}
              error={!!validation.phone}
              validate={(isValid) => onChangeValidation("phone", !isValid)}
              value={team.phone}
            />
          </div>
          <div className="flex flex-align-center mb-16 mobile-block">
            <label className="accent-text-dark">Goal</label>
            <div className="flex flex-align-center">
              <input
                type="number"
                className={`custom-input goal-input ${
                  !!validation.goal ? "error" : ""
                }`}
                min={1}
                name="goal"
                onChange={(e) => onChangeTeam(e.target.name, e.target.value)}
                onBlur={(e) => {
                  const isValid =
                    !e.target.value || Number(e.target.value) >= 0;
                  onChangeValidation("goal", !isValid);
                }}
                value={team.goal}
              />
              {validation.goal && (
                <span className="error-message goal-error">Invalid Goal</span>
              )}
              <label className="accent-text-dark tickets-text">Tickets</label>
            </div>
          </div>
          <hr className="team-modal-separator" />
          <div className="modal-btns flex-align-center mt-16">
            <button
              className="btn cancel-btn link-text uppercase-text fw-500"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className="btn save-btn link-text uppercase-text fw-500 ml-24"
              disabled={_isEqual(teamForEdit, team) || !isValid() || loading}
              onClick={onSubmit}
            >
              {loading ? "Saving..." : team.id ? "Done" : "Add Team"}
            </button>
          </div>
          {submitAttempted && !!errorMessage && (
            <p className="text-right mt-8 error-message relative">
              {errorMessage}
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
}

import React, { useState } from "react";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import PaginatedTable from "../../../../components/PaginatedTable";
import { WizardStepError, WizardStepProps } from "../WizardHelpers";
import { StudentDetails } from "../../../../lib/apiRequests/ApiTypes";
import { formatFullName } from "../../../../lib";
import { useMobile } from "../../../../themes";
import { WizardFooter } from "../WizardFooter";

const sortByName = (students: StudentDetails[]) =>
  students.sort((a, b) => {
    const aName = formatFullName(a.studentFirstName, a.studentLastName);
    const bName = formatFullName(b.studentFirstName, b.studentLastName);
    return aName.localeCompare(bName);
  });

export const ReviewRecipients = React.memo(function ReviewRecipients(
  props: WizardStepProps,
) {
  const {
    back,
    next,
    onClose,
    recipients: { additionalStudents, filteredStudents },
    removeRecipients,
  } = props;

  const isMobile = useMobile();

  const [page, setPage] = useState(1);
  const [errors, setErrors] = useState<WizardStepError>({});

  function goToNextStep() {
    if (!additionalStudents.length && !filteredStudents.length) {
      setErrors((errors) => ({
        ...errors,
        validationError:
          "All recipients have been removed. Please go back to Step 1 to choose recipients.",
      }));
    } else {
      next();
    }
  }

  // filter out additionalStudents that are included in the filteredStudents list to avoid displaying duplicates
  const additionalStudentsFiltered = additionalStudents.filter(
    (s) => !filteredStudents.find((fs) => fs.personID === s.personID),
  );

  const recipients: StudentDetails[] = sortByName([
    ...additionalStudentsFiltered,
    ...filteredStudents,
  ]);

  const resultsPerPage = 8;
  const startAtRecord = resultsPerPage * (page - 1);

  const recordsToShow = recipients.filter(
    (_, index) => index >= startAtRecord && index < startAtRecord + 8,
  );

  return (
    <div className="page">
      <div className="sms-wizard-container">
        <Typography variant="h6" className="mb-24 mobile-mb-16">
          Review recipients
        </Typography>
        <div>
          The following students will receive this SMS message. You may remove
          individual students from the list.
        </div>
      </div>
      <div>
        <div className="sms-wizard-container">
          <div>
            <PaginatedTable
              name="Recipients"
              overrideMarginBottom={true}
              page={page}
              setPage={setPage}
              records={recordsToShow}
              renderHeaderRow={() => <Header />}
              renderRow={(student: StudentDetails, index: number) => (
                <ListRow
                  student={student}
                  isPhone={isMobile}
                  removeStudent={(st) => {
                    removeRecipients([st]);
                  }}
                  key={index}
                />
              )}
              resultsIncrement={resultsPerPage}
              resultsView={resultsPerPage}
              showResultsTotal={true}
              success={true}
              totalCount={recipients.length}
            />
          </div>
        </div>
        <div className="error-text mt-8">{errors.validationError}</div>
      </div>

      <WizardFooter
        onClose={onClose}
        next={goToNextStep}
        nextDisabled={!!errors.validationError}
        back={back}
      />
    </div>
  );
});

interface ListRowProps {
  isPhone: boolean;
  removeStudent: (student: StudentDetails) => void;
  student: StudentDetails;
}
const ListRow = ({ isPhone, removeStudent, student }: ListRowProps) => {
  const participatedIn =
    student.programsParticipatedIn?.[0] ||
    student.rsvpEventsParticipatedIn?.[0] ||
    student.rsvpResourcesParticipatedIn?.[0] ||
    student.rsvpTypesParticipatedIn?.[0];

  return isPhone ? (
    <div className="paginated-table-row students-list-table-row pointer sms-review-mobile">
      <div className="first-row">
        <div className="student-name">
          {formatFullName(student.studentFirstName, student.studentLastName)}
          {!student.cell && (
            <Tooltip
              title="This student has no cell phone number on file and will not get the text message"
              arrow
            >
              <i className="material-icons sms-warning-icon">warning</i>
            </Tooltip>
          )}
        </div>
        <div className="accent-text">
          {student.isJewish ? "Student identifies as Jewish" : ""}
        </div>
        <div className="tag-name">
          {student.chabadHouseTagNames && student.chabadHouseTagNames[0]}
        </div>
      </div>
      <div className="second-row">
        {student.campusName}
        {student.campusName && student.graduationYear && (
          <div className="dash-outer-div">
            <div className="dash-inner-div">
              <hr />
            </div>
          </div>
        )}
        {student.graduationYear && "Grad year "} {student.graduationYear}
      </div>
      <div className="program-participated">
        <div>{participatedIn && `Participated in ${participatedIn}`}</div>
        <div>
          <IconButton
            aria-label="delete"
            style={{ padding: "0px" }}
            onClick={() => {
              removeStudent(student);
            }}
          >
            <i className="material-icons remove-icon">delete</i>
          </IconButton>
        </div>
      </div>
    </div>
  ) : (
    <div className="paginated-table-row wizard-review-row pointer sms-review-grid-cols">
      <div className="desktop-display-contents mobile-flex flex-justify-space full-width">
        <div className="desktop-display-contents ">
          <div>
            {formatFullName(student.studentFirstName, student.studentLastName)}
            {!student.cell && (
              <Tooltip
                title="This student has no cell phone number on file and will not get the text message"
                arrow
              >
                <i className="material-icons sms-warning-icon">warning</i>
              </Tooltip>
            )}
          </div>
          <div>
            {student.chabadHouseTagNames && student.chabadHouseTagNames[0]}
          </div>
          <div>{student.isJewish ? "Yes" : "No"}</div>
          <div>{student.graduationYear}</div>
          {/* programs filter data */}
          <div>{participatedIn && `Participated in ${participatedIn}`}</div>
          <div>{student.campusName}</div>
          <div className="remove-icon-container" style={{ textAlign: "end" }}>
            <IconButton
              aria-label="delete"
              onClick={() => {
                removeStudent(student);
              }}
            >
              <i className="material-icons link-text-secondary accent-text">
                delete
              </i>
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};
const headerTitles = [
  "Name",
  "Group",
  "Jewish",
  "Grad year",
  "Participated in",
  "School",
];
const Header = () => (
  <div className="paginated-table-header-row students-list-table-row sms-review-grid-cols">
    {headerTitles.map((title, index) => (
      <p key={index} className="fw-900">
        {title}
      </p>
    ))}
  </div>
);

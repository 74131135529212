import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormFieldProps } from "../form";
import {
  AutocompleteInput,
  AutocompleteInputProps,
} from "../inputs/AutocompleteInput";

type FormAutocompleteFieldProps = {
  onValueChange?: (val: any) => void; // optional callback for value change - in addition to the default handler
} & FormFieldProps &
  Omit<AutocompleteInputProps, "onChange">;

export const FormAutocompleteField = React.memo(
  /**
   *
   */
  function FormAutocompleteField({
    name,
    onValueChange,
    ...passProps
  }: FormAutocompleteFieldProps) {
    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => {
          const { onChange, ...fieldProps } = field;
          const { error } = fieldState;

          return (
            <AutocompleteInput
              {...fieldProps}
              id={name}
              onChange={(name, value) => {
                onChange({ target: { name, value } });
                onValueChange && onValueChange(value);
              }}
              {...passProps}
              {...(error
                ? {
                    error: true,
                    helperText: error.message,
                  }
                : {})}
            />
          );
        }}
      />
    );
  },
);

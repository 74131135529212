import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  FileUploadInput,
  FileUploadInputProps,
} from "../inputs/FileUploadInput";

type FormFileUploadFieldProps = {} & Omit<
  FileUploadInputProps,
  "onChange" | "value"
>;

export const FormFileUploadField = React.memo(
  /**
   *
   */
  function FormFileUploadField({
    name,
    ...passProps
  }: FormFileUploadFieldProps) {
    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          const { onChange, ...fieldProps } = field;
          const { error } = fieldState;
          return (
            <FileUploadInput
              {...fieldProps}
              error={error}
              onChange={(name, value) => {
                onChange({ target: { name, value } });
              }}
              {...passProps}
            />
          );
        }}
      />
    );
  },
);

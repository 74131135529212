import { Box, Dialog, styled } from "@material-ui/core";

export const BillingContainerStyled = styled(Box)(({ theme }) => ({
  [`${theme.breakpoints.down("sm")}`]: {
    padding: "24px 16px 0px",
  },
}));

export const BillingCardStyled = styled(Box)(() => ({
  borderRadius: "3px",
  width: "100%",
  border: "1px solid #EDECEC",
  padding: "16px",
}));

// #region Billing Header
export const BillingHeaderStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: "40px",
  [`${theme.breakpoints.up("sm")}`]: {
    justifyContent: "space-between",
    columnGap: "40px",
  },
  [`${theme.breakpoints.down("sm")}`]: {
    flexDirection: "column",
    rowGap: "16px",
  },
}));

export const BillingHeaderCardStyled = styled(BillingCardStyled)(() => ({
  padding: "16px",
  "& > div:first-of-type": {
    minHeight: "38px",
    marginBottom: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "&.payment": {
    backgroundColor: "#F4F4F5",
    borderColor: "#F4F4F5",
    "& .MuiTypography-caption": {
      display: "block",
      minHeight: "20px",
    },
  },
  "&.error": {
    borderColor: "#FF6B6B",
    backgroundColor: "#FFE1E1",
    "& button, .MuiTypography-caption": {
      color: "#FF5252",
    },
  },
}));

export const BillingStatusChipStyled = styled(Box)(({ theme }) => ({
  fontSize: "13px",
  borderRadius: "12px",
  padding: "4px 8px",
  backgroundColor: "#4FCEC5",
  width: "fit-content",
  "&.warning": {
    backgroundColor: "#FF9E57",
  },
  "&.error": {
    backgroundColor: "#FF6B6B",
  },
  [`${theme.breakpoints.up("sm")}`]: {
    marginLeft: "12px",
  },
}));

// #endregion

// #region Billing Dialog

export const BillingDialogStyled = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    maxWidth: "640px",
  },
  "& .MuiDialogContent-root": {
    padding: "24px",
    marginBottom: "88px",
  },
  // content style overrides
  "& .medium-text.mb-16": {
    marginBottom: "24px",
  },
  "& label.small-text.accent-text": {
    fontSize: "14px",
    color: "#524E4E",
    width: "180px !important",
    minWidth: "180px !important",
  },
  "& .custom-checkbox-container label": {
    fontSize: "16px",
  },
  "& input, .custom-select__placeholder, .custom-select__single-value": {
    fontSize: "14px",
  },
  "& .custom-input, .custom-select": {
    height: "40px !important",
  },
  "& .custom-select__control": {
    height: "38px !important",
  },
  "& .custom-select__control--is-focused": {
    border: "none",
    outline: "none !important",
    boxShadow: "none",
  },
}));

export const BillingDialogFooterStyled = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  height: "88px",
  padding: "24px",
  backgroundColor: "#FFF",
  borderTop: "1px solid #EDECEC",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  "& button:first-of-type": {
    marginRight: "16px",
  },
  "& .error-text": {
    position: "absolute",
    bottom: 0,
    fontSize: "13px",
  },
  [`${theme.breakpoints.down("sm")}`]: {
    justifyContent: "flex-start",
    columnGap: "16px",
    paddingRight: "98px",
    "& button": {
      width: "100%",
      marginRight: "0px !important",
    },
  },
}));

// #endregion

// #region Credits Billing Breakdown

export const BillingBreakdownHeaderStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: "24px",
  "& .react-daterange-picker__wrapper": {
    border: "none",
    "& span, input, i": {
      color: "#2774ae",
      fontWeight: "600",
    },
    "& i": {
      fontSize: "16px",
    },
  },
  [`${theme.breakpoints.up("sm")}`]: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`${theme.breakpoints.down("sm")}`]: {
    flexDirection: "column",
    alignItems: "flex-start",
    rowGap: "8px",
  },
}));

export const BillingBreakdownUpperStatsStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  "& > div": {
    border: "1px solid",
    borderLeftWidth: "10px",
    borderRadius: "3px",
    padding: "16px",
    width: "100%",
    "&.total-cost": {
      borderColor: "#4fcec5",
    },
    "&.coci-cost": {
      borderColor: "#7c71f9",
    },
    "&.my-cost": {
      borderColor: "#ff9e57",
    },
  },
  [`${theme.breakpoints.up("sm")}`]: {
    justifyContent: "space-between",
    columnGap: "32px",
    marginBottom: "24px",
  },
  [`${theme.breakpoints.down("sm")}`]: {
    columnGap: "8px",
    margin: "0px -8px 40px",
    "& > div": {
      borderLeftWidth: "1px",
      textAlign: "center",
      padding: "8px",
    },
  },
}));

export const BillingBreakdownLowerStatsStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  [`${theme.breakpoints.up("sm")}`]: {
    columnGap: "32px",
  },
  [`${theme.breakpoints.down("sm")}`]: {
    flexDirection: "column-reverse",
    rowGap: "24px",
    "& > div": {
      border: "unset",
      padding: "unset",
    },
  },
}));

export const RewardsChartKeyStyled = styled(Box)(({ theme }) => ({
  "& i": {
    fontSize: "16px",
    marginRight: "4px",
  },
  [`${theme.breakpoints.up("sm")}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(4, auto)",
    columnGap: "22px",
    rowGap: "14px",
    "& p": {
      display: "flex",
      alignItems: "center",
    },
  },
  [`${theme.breakpoints.down("sm")}`]: {
    "& > div": {
      marginBottom: "16px",
    },
    "& h6": {
      display: "flex",
      alignItems: "center",
      marginBottom: "4px",
    },
  },
}));

export const RewardsLineChartContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  margin: "36px 0px 24px",
  height: "16px",
  position: "relative",
  "& > div": {
    position: "relative",
    "& > span": {
      position: "absolute",
      bottom: "10px",
      left: "0px",
      right: "0px",
      textAlign: "center",
      lineHeight: "12px",
    },
  },
  "& > div:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& > div:last-of-type": {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));

export const InvoicesListRowStyled = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid #d8d8dd",
  padding: "16px 0px",
  "& i": {
    fontSize: "12px",
    marginRight: "4px",
  },
  [`${theme.breakpoints.up("sm")}`]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr .5fr .5fr .5fr .5fr",
    columnGap: "16px",
    "& p": {
      display: "flex",
      alignItems: "center",
    },
  },
  [`${theme.breakpoints.down("sm")}`]: {
    display: "flex",
    flexDirection: "column",
    rowGap: "4px",
    padding: "12px 16px",
    margin: "0px -16px",
    "& span": {
      display: "flex",
      alignItems: "center",
    },
    "& i": {
      fontSize: "10px",
    },
  },
}));

// #endregion

import React, { useState, MouseEvent, Dispatch, SetStateAction } from "react";
import { Button, ButtonProps, Menu, MenuItem } from "@material-ui/core";
import { TextInput } from "./react-hook-form";

export interface ButtonMenuProps {
  buttonProps?: ButtonProps;
  disabled?: boolean;
  id: string;
  icon?: string;
  text?: string;
  title?: string;
  menuItems: JSX.Element[];
  hasSearch?: boolean;
  searchText?: string;
  setSearchText?: Dispatch<SetStateAction<string>>;
}

export default function ButtonMenu({
  disabled,
  icon,
  text,
  title,
  id,
  buttonProps,
  menuItems,
  hasSearch,
  searchText,
  setSearchText,
}: ButtonMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchText && setSearchText("");
  };

  return (
    <>
      <Button
        {...buttonProps}
        disabled={disabled}
        variant="contained"
        aria-controls="button-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {text}
        {icon && <i className="material-icons">{icon}</i>}
      </Button>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        style={{ maxHeight: 600 }}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleClose}
        onClick={handleClose}
      >
        {title && <MenuItem disabled={true}>{title}</MenuItem>}
        {hasSearch && setSearchText && (
          <div>
            <TextInput
              style={{ padding: "2px 10px 10px 10px" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(event) => event.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        )}
        {menuItems}
      </Menu>
    </>
  );
}

import React, { useEffect } from "react";
import { withAppInsights } from "../../lib";
import { RsvpActions, useAction } from "../../state";
import SmsMailbox from "./SmsMailbox";

function SMSMessagingPage() {
  const getRsvpEnrollment = useAction(RsvpActions.getRsvpEnrollment);

  useEffect(() => {
    getRsvpEnrollment();
  }, [getRsvpEnrollment]);

  return <SmsMailbox />;
}

export default withAppInsights(SMSMessagingPage);

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Avatar, Box, MenuItem, styled, Typography } from "@material-ui/core";
import { useMobile } from "../../../../themes";
import {
  calculateMessagePricing,
  getEventDetailsText,
  getResourceDetailsText,
  getRsvpStatusText,
  MessagePricing,
  RsvpActionTypes,
  WizardStepProps,
  WizardStepError,
} from "../WizardHelpers";
import { FileUploadInput } from "../../../../components/react-hook-form";
import { PROFILE_PLACEHOLDER_URL } from "../../../../lib/utils";
import ButtonMenu from "../../../../components/ButtonMenu";
import {
  getRsvpEventSchedules,
  getRsvpResources,
} from "../../../../lib/apiRequests/RsvpApiRequests";
import { EventSchedule, Resource } from "../../../../lib/apiRequests/ApiTypes";
import { WizardFooter } from "../WizardFooter";
import { SmsCostEstimate } from "../SmsCostEstimate";
import WizardError from "../WizardError";
import Loader from "../../../../components/Loader";

const ImageUploadContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  width: "fit-content",
  cursor: "pointer",
  color: theme.palette.primary.main,
  textTransform: "uppercase",
  "&:hover": {
    opacity: ".8",
  },
  "& .MuiAvatar-root": {
    height: "80px",
    width: "80px",
    marginRight: "24px",
  },
}));

export const ComposeMessage = React.memo(function ComposeMessage(
  props: WizardStepProps,
) {
  const {
    back,
    chabadHouseEnrollmentData,
    eventScheduleId,
    messageImage,
    messageText,
    next,
    onClose,
    recipients,
    resourceId,
    rsvpActionType,
    setMessageImage,
    setMessageText,
  } = props;

  const { forChabadHouseID: chabadHouseId, id: rsvpEnrollmentId } =
    chabadHouseEnrollmentData;
  const [loading, setLoading] = useState(false);
  const [pricingData, setPricingData] = useState<MessagePricing>({
    price: null,
    balance: null,
    segments: null,
  });
  const [events, setEvents] = useState<EventSchedule[]>([]);
  const [eventSearch, setEventSearch] = useState("");
  const [resources, setResources] = useState<Resource[]>([]);
  const [resourceSearch, setResourceSearch] = useState("");
  const [errors, setErrors] = useState<
    WizardStepError & { pricingError?: string }
  >({});

  const messsageTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const textLengthError = messageText?.length > 1600;
  const isMobile = useMobile();

  const insertTextByCursor = useCallback(
    (text: string) => {
      setMessageText((messageText) => {
        if (!messsageTextAreaRef.current) {
          return text;
        }
        const textBeforeCursorPosition = messageText.substring(
          0,
          messsageTextAreaRef.current.selectionEnd,
        );
        const textAfterCursorPosition = messageText.substring(
          messsageTextAreaRef.current.selectionEnd,
          messageText.length,
        );

        return textBeforeCursorPosition + text + textAfterCursorPosition;
      });
    },
    [setMessageText],
  );

  function insertPersonalization(personalizationText: string) {
    insertTextByCursor(personalizationText);
  }

  function insertEventDetails(eventScheduleId: number) {
    const selectedEvent = events.find(
      (ev) => ev.eventScheduleID === eventScheduleId,
    );
    if (selectedEvent) {
      let eventText = messageText ? "\n" : "";
      eventText += getEventDetailsText(selectedEvent);
      insertTextByCursor(eventText);
    }
  }

  function insertResourceDetails(resourceId: number) {
    const selectedResource = resources.find((r) => r.resourceID === resourceId);
    if (selectedResource) {
      let resourceText = messageText ? "\n" : "";
      resourceText += getResourceDetailsText(selectedResource);

      insertTextByCursor(resourceText);
    }
  }

  async function onClickCalculate() {
    setErrors((errors) => ({ ...errors, pricingError: undefined }));
    if (chabadHouseId) {
      const { pricing, error } = await calculateMessagePricing(
        chabadHouseId,
        recipients,
        !!messageImage,
        messageText,
      );
      if (error) {
        setErrors((errors) => ({ ...errors, pricingError: error }));
      }
      pricing && setPricingData(pricing);
    }
  }

  const isValid = useCallback(() => {
    if (!messageText && !messageImage) {
      setErrors((errors) => ({
        ...errors,
        validationError:
          "Please enter message text or upload an image to send.",
      }));
      return false;
    }
    return true;
  }, [messageImage, messageText]);

  const submitStep = useCallback(() => {
    if (isValid()) {
      next();
    }
  }, [isValid, next]);

  const setRsvpEventText = useCallback(() => {
    if (rsvpActionType && rsvpActionType in RsvpActionTypes) {
      const event = events.find((e) => e.eventScheduleID === eventScheduleId);
      if (event) {
        const statusText = getRsvpStatusText(rsvpActionType, "event");
        const eventText = getEventDetailsText(event);
        insertTextByCursor(
          "Hi [first_name],\n" + statusText + "\n" + eventText,
        );
      }
    }
  }, [eventScheduleId, events, insertTextByCursor, rsvpActionType]);

  const setRsvpResourceText = useCallback(() => {
    if (rsvpActionType && rsvpActionType in RsvpActionTypes) {
      const resource = resources.find((r) => r.resourceID === resourceId);
      if (resource) {
        const statusText = getRsvpStatusText(rsvpActionType, "resource");
        const resourceText = getResourceDetailsText(resource);
        insertTextByCursor(
          "Hi [first_name],\n" + statusText + "\n" + resourceText,
        );
      }
    }
  }, [insertTextByCursor, resourceId, resources, rsvpActionType]);

  const loadEvents = useCallback(async () => {
    if (rsvpEnrollmentId) {
      const { data, error } = await getRsvpEventSchedules(rsvpEnrollmentId, {
        export: true,
        includeChabadHouseAddress: true,
        occurrenceStatus: "upcoming",
        settingStates: ["active", "paused"],
      });
      if (error) {
        setErrors((errors) => ({ ...errors, dataRetrievalError: error }));
      } else if (data) {
        setEvents(data);
      }
    }
  }, [rsvpEnrollmentId]);

  const loadResources = useCallback(async () => {
    if (rsvpEnrollmentId) {
      const { data, error } = await getRsvpResources(rsvpEnrollmentId, {
        ignorePagination: true,
      });
      if (error) {
        setErrors((errors) => ({ ...errors, dataRetrievalError: error }));
      } else if (data) {
        setResources(data);
      }
    }
  }, [rsvpEnrollmentId]);

  const loadData = useCallback(async () => {
    setLoading(true);
    Promise.all([loadEvents(), loadResources()]);
    setLoading(false);
  }, [loadEvents, loadResources]);

  useEffect(() => {
    if (eventScheduleId && events.length) {
      setRsvpEventText();
    }
  }, [eventScheduleId, events.length, setRsvpEventText]);

  useEffect(() => {
    if (resourceId && resources.length) {
      setRsvpResourceText();
    }
  }, [resourceId, resources.length, setRsvpResourceText]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onRefresh = useCallback(() => {
    if (errors.dataRetrievalError) {
      setErrors((errors) => ({ ...errors, dataRetrievalError: undefined }));
    }
    loadData();
  }, [errors.dataRetrievalError, loadData]);

  const filteredEvents = eventSearch
    ? events.filter((e) =>
        e.name.toLowerCase().includes(eventSearch.toLowerCase()),
      )
    : events;

  const filteredResources = resourceSearch
    ? resources.filter((r) =>
        r.name.toLowerCase().includes(resourceSearch.toLowerCase()),
      )
    : resources;

  useEffect(() => {
    if (errors.validationError && (messageImage || messageText)) {
      setErrors((errors) => ({ ...errors, validationError: undefined }));
    }
  }, [errors.validationError, messageImage, messageText]);

  return (
    <div className="page">
      <div className="sms-wizard-container">
        <Typography variant="h6" className="mb-24">
          Compose Message
        </Typography>
      </div>
      {errors.dataRetrievalError ? (
        <WizardError
          onRefresh={onRefresh}
          errorMessage={errors.dataRetrievalError}
        />
      ) : loading ? (
        <Loader />
      ) : (
        <div>
          <div>
            <div className="mt-24 sms-wizard-container">
              <div>
                <div className="flex flex-column">
                  <div className="compose-message-section fw mb-16">
                    <textarea
                      className={`custom-input ${textLengthError && "error"}`}
                      style={{ border: "none" }}
                      value={messageText}
                      ref={messsageTextAreaRef}
                      placeholder="Type message here..."
                      onChange={(e) => {
                        setMessageText(e.target.value);
                      }}
                    />
                    <div className="sms-personalize-button-area">
                      <ButtonMenu
                        buttonProps={{
                          style: {
                            textTransform: "capitalize",
                            marginBottom: isMobile ? 8 : 0,
                          },
                        }}
                        id="personalization-menu"
                        text="Insert personalization"
                        menuItems={[
                          <MenuItem
                            key={1}
                            onClick={() =>
                              insertPersonalization("[student_first_name]")
                            }
                          >
                            First Name
                          </MenuItem>,
                          <MenuItem
                            key={2}
                            onClick={() =>
                              insertPersonalization("[student_full_name]")
                            }
                          >
                            Full Name
                          </MenuItem>,
                        ]}
                      />
                      <ButtonMenu
                        buttonProps={{
                          style: {
                            textTransform: "capitalize",
                            marginLeft: isMobile ? 0 : 12,
                            marginBottom: isMobile ? 8 : 0,
                          },
                        }}
                        id="insert-event-menu"
                        text="Insert event details"
                        hasSearch={true}
                        searchText={eventSearch}
                        setSearchText={setEventSearch}
                        menuItems={filteredEvents.map(
                          (event: EventSchedule) => (
                            <MenuItem
                              key={event.eventScheduleID}
                              onClick={() => {
                                insertEventDetails(event.eventScheduleID);
                              }}
                            >
                              {event.name}
                            </MenuItem>
                          ),
                        )}
                      />
                      <ButtonMenu
                        buttonProps={{
                          style: {
                            textTransform: "capitalize",
                            marginLeft: isMobile ? 0 : 12,
                          },
                        }}
                        id="insert-resource-menu"
                        text="Insert resource details"
                        hasSearch={true}
                        searchText={resourceSearch}
                        setSearchText={setResourceSearch}
                        menuItems={filteredResources.map(
                          (resource: Resource) => (
                            <MenuItem
                              key={resource.resourceID}
                              onClick={() => {
                                insertResourceDetails(resource.resourceID);
                              }}
                            >
                              {resource.name}
                            </MenuItem>
                          ),
                        )}
                      />
                    </div>
                    <div className="total-characters">
                      <p className={textLengthError ? "error-text" : ""}>
                        {messageText?.length ?? 0} / {1600}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`accent-text small-text flex flex-justify-end ${
                      textLengthError ? "error-text" : ""
                    }`}
                  >
                    Note that Twilio has a character limit of 1600 characters,
                    emojis count as 2 characters.
                  </div>
                </div>
                <div>
                  <div>
                    <Typography variant="h6">Add image</Typography>
                    <div className="add-image-text">
                      Attaching an image converts the SMS to a MMS message. MMS
                      messages will be charged MMS pricing.
                    </div>
                  </div>
                  <div className="upload-image-div">
                    <FileUploadInput
                      value={messageImage}
                      accept="image/jpeg, image/jpg, image/gif, image/png, image/bmp, image/tiff"
                      onChange={(e) => {
                        setMessageImage(e.target.value.previewUrl);
                      }}
                      name="sms-image"
                      uploadComponent={(fileOrUrl) => (
                        <ImageUploadContainerStyled>
                          <Avatar
                            variant="square"
                            src={
                              !fileOrUrl
                                ? PROFILE_PLACEHOLDER_URL
                                : typeof fileOrUrl === "string"
                                ? fileOrUrl
                                : fileOrUrl.previewUrl
                            }
                          />
                          <Typography variant="body2">
                            {fileOrUrl ? "Change" : "Upload"}
                          </Typography>
                        </ImageUploadContainerStyled>
                      )}
                    />
                    {!!messageImage && (
                      <div
                        onClick={() => {
                          setMessageImage(null);
                        }}
                        className="link-text uppercase-text relative"
                        style={{ left: "-60px" }}
                      >
                        Remove
                      </div>
                    )}
                  </div>
                </div>
                <SmsCostEstimate
                  error={errors.pricingError}
                  pricingData={pricingData}
                  recipientCount={
                    recipients.additionalStudents.length +
                    recipients.filteredStudents.length
                  }
                  onClickCalculate={onClickCalculate}
                />
              </div>
            </div>
          </div>
          <div className="error-text mt-8">{errors.validationError}</div>
        </div>
      )}
      <WizardFooter
        onClose={onClose}
        next={submitStep}
        nextDisabled={
          !!(
            errors.validationError ||
            errors.dataRetrievalError ||
            textLengthError
          )
        }
        back={back}
      />
    </div>
  );
});

import React, { memo, useCallback, useEffect, useState } from "react";
/* @ts-ignore */
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Box, Typography } from "@material-ui/core";
import Loader from "../../../../components/Loader";
import CreditsBillingRewardsChart from "./CreditsBillingRewardsChart";
import CreditsBillingInvoicesList from "./CreditsBillingInvoicesList";
import { EduBillingCosts, EduEnrollment } from "./BillingTypes";
import {
  BillingCardStyled,
  BillingBreakdownHeaderStyled,
  BillingBreakdownUpperStatsStyled,
  BillingBreakdownLowerStatsStyled,
} from "./Billing.styles";
import { EduActions, useAppDispatch } from "../../../../state";
import { formatCurrency, removeTimezoneFormatFromDate } from "../../../../lib";
import { useMobile } from "../../../../themes";
import moment from "moment";

// #region Date range helpers

const getDefaultDateRange = () => {
  const today = new Date();
  return [
    new Date(today.getFullYear(), today.getMonth(), 1), // first day of the current month
    new Date(today.getFullYear(), today.getMonth() + 1, 0), // last day of the current month
  ];
};

const getMinDateRangeDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 2); // 2 years ago
  return date;
};

export const formatDateRangeParams = (dateRange: Date[]) => {
  // because billing dates are EST-based we send non-timezone formatted date string params to prevent axios from auto-converting from local TZ to UTC
  // the server will consider the date EST across all usages and will convert to UTC as needed
  return {
    billableDateFrom: removeTimezoneFormatFromDate(dateRange[0]),
    billableDateTo: removeTimezoneFormatFromDate(
      moment(dateRange[1]).endOf("day"),
    ),
  };
};

// #endregion

interface CreditsBillingBreakdownProps {
  billingRefreshCount: number;
  enrollment: EduEnrollment;
  pageRoute: any;
}

function CreditsBillingBreakdown({
  billingRefreshCount,
  enrollment,
  pageRoute,
}: CreditsBillingBreakdownProps) {
  const dispatch = useAppDispatch();
  const isMobile = useMobile("sm");

  const [dateRange, setDateRange] = useState(getDefaultDateRange());

  const [billingCosts, setBillingCosts] = useState<EduBillingCosts>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const retrieveBillingCosts = useCallback(async () => {
    setLoading(true);

    const { data, error } = await dispatch(
      EduActions.getEduEnrollmentBillingCosts(
        enrollment.id,
        formatDateRangeParams(dateRange),
      ),
    );
    if (error) {
      setError(true);
    } else {
      setBillingCosts(data);
    }

    setLoading(false);
  }, [dateRange, dispatch, enrollment.id]);

  useEffect(() => {
    retrieveBillingCosts();
  }, [billingRefreshCount, retrieveBillingCosts]);

  return (
    <BillingCardStyled>
      <BillingBreakdownHeaderStyled>
        <Typography variant={isMobile ? "h4" : "h6"}>
          Credits billing breakdown
        </Typography>
        <DateRangePicker
          calendarIcon={<i className="material-icons">keyboard_arrow_down</i>}
          clearIcon={null}
          minDate={getMinDateRangeDate()}
          onChange={setDateRange}
          value={dateRange}
        />
      </BillingBreakdownHeaderStyled>
      {loading ? (
        <Loader />
      ) : error ? (
        <Typography variant="subtitle2" color="error">
          Something went wrong and we could not retrieve billing details. Please
          refresh the page to try again.
        </Typography>
      ) : (
        billingCosts && (
          <>
            <BillingBreakdownUpperStatsStyled>
              {[
                {
                  className: "total-cost",
                  label: "Credits Redeemed",
                  value:
                    billingCosts.totalCociCost +
                    billingCosts.totalChabadHouseCost,
                },

                {
                  className: "coci-cost",
                  label: "COCI cost",
                  value: billingCosts.totalCociCost,
                },
                {
                  className: "my-cost",
                  label: "My cost",
                  value: billingCosts.totalChabadHouseCost,
                },
              ].map(({ className, label, value }, i) => (
                <Box className={className} key={i}>
                  <Typography variant={isMobile ? "subtitle2" : "h6"}>
                    ${formatCurrency(value)}
                  </Typography>
                  <Typography
                    variant={isMobile ? "caption" : "body2"}
                    color={isMobile ? "textSecondary" : "textPrimary"}
                  >
                    {label}
                  </Typography>
                </Box>
              ))}
            </BillingBreakdownUpperStatsStyled>
            <BillingBreakdownLowerStatsStyled>
              <CreditsBillingInvoicesList
                dateRange={dateRange}
                enrollmentId={enrollment.id}
                pageRoute={pageRoute}
              />
              <CreditsBillingRewardsChart billingCosts={billingCosts} />
            </BillingBreakdownLowerStatsStyled>
          </>
        )
      )}
    </BillingCardStyled>
  );
}

export default memo(CreditsBillingBreakdown);

import React from "react";
import { Box } from "@material-ui/core";
import RsvpBreadcrumbsHeader from "../../RsvpBreadcrumbsHeader";
import { Navigation, PageURL, isMobileView } from "../../../../../lib";
import Loader from "../../../../../components/Loader";
import { SmsWizard } from "../../../../sms/newWizard/Wizard";
import {
  RsvpActionTypes,
  WizardStepIdentifiers,
} from "../../../../sms/newWizard/WizardHelpers";

export default class CreateRsvpItem extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      showEventCreatedModal: false,
      showSendSmsModal: false,
    };
  }
  componentDidMount() {
    //scroll to top of page on mount for when navigating from low scroll position within event schedules list
    if (window.scrollY > 0) {
      window.scrollTo(0, 0);
    }

    this.getRsvpItem();
  }

  componentWillUnmount() {
    const { clearRsvpItem } = this.props;
    // TODO when cloning is implemented for resources, this check will no longer be needed
    if (clearRsvpItem) {
      clearRsvpItem();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      pageRoute: {
        query: { cloneEventScheduleId },
      },
    } = this.props;

    if (
      prevProps.pageRoute.query.cloneEventScheduleId !== cloneEventScheduleId
    ) {
      this.getRsvpItem();
    }
  }

  getRsvpItem = async (isRefresh = false) => {
    const {
      getRsvpItem,
      pageRoute: {
        query: { cloneEventScheduleId },
      },
    } = this.props;
    if (cloneEventScheduleId) {
      await getRsvpItem(cloneEventScheduleId, isRefresh);
    }
  };

  redirectToList = () => {
    const {
      pageRoute: { page, params },
    } = this.props;

    //after a new event/resource is created, navigate to event schedules/resources list
    this.setState({ requestedNavigation: true }, () => {
      Navigation.go(
        PageURL.to(
          page,
          {
            ...params,
            rsvpItemId: undefined,
          },
          null,
        ),
      );
    });
  };

  toggleShowEventCreated = () => {
    const showEventCreatedModal = !this.state.showEventCreatedModal;

    this.setState(
      {
        showEventCreatedModal,
      },
      () => {
        if (!showEventCreatedModal) {
          //after a new event/resource is created, navigate to event schedules/resources list
          this.redirectToList();
        }
      },
    );
  };

  toggleShowSendSms = () => {
    const showSendSmsModal = !this.state.showSendSmsModal;

    this.setState(
      {
        showSendSmsModal,
      },
      () => {
        if (!showSendSmsModal) {
          //after a new event/resource is created, navigate to event schedules/resources list
          this.redirectToList();
        }
      },
    );
  };

  render() {
    const {
      pageRoute,
      SettingsComponent,
      ModalComponent,
      eventSchedule: { data: eventSchedule, loading, success },
      resource: { data: resource },
    } = this.props;
    const isClone = !!pageRoute.query.cloneEventScheduleId;
    const { showEventCreatedModal, showSendSmsModal } = this.state;
    const hideSettings =
      (isMobileView() && showEventCreatedModal) || // on mobile don't show settings after event creation
      (isClone && !eventSchedule); // when cloning an event, don't show the settings page until the eventSchedule is loaded

    return (
      <div className="full-width">
        <Box>
          <RsvpBreadcrumbsHeader pageRoute={pageRoute} />
        </Box>
        {/* we are only loading data when we are cloning */}
        {isClone && loading && <Loader />}
        {isClone && !loading && !success && (
          <div className="text-center">
            <img src="/images/error.svg" alt="error-robot" height="320" />
            <p
              className="text-center error-message mt-24"
              style={{ position: "relative" }}
            >
              Something went wrong.
            </p>
            <p
              className="text-center error-message mt-24"
              style={{ position: "relative" }}
            >
              Our server is being stubborn. Sorry for the inconvenience.
            </p>
          </div>
        )}
        {!hideSettings && (
          <SettingsComponent
            {...this.props}
            isNew={true}
            onCreate={this.toggleShowEventCreated}
          />
        )}
        {showEventCreatedModal && (
          <ModalComponent
            {...this.props}
            isMobile={isMobileView()}
            toggleShowEventCreated={this.toggleShowEventCreated}
            openSendSmsModal={() => {
              this.setState({
                showEventCreatedModal: false,
              });
              this.toggleShowSendSms();
            }}
          />
        )}
        {showSendSmsModal && (
          <SmsWizard
            eventScheduleId={eventSchedule?.eventScheduleID}
            onClose={this.toggleShowSendSms}
            openToStep={WizardStepIdentifiers.SelectRecipients}
            resourceId={resource?.resourceId}
            rsvpActionType={RsvpActionTypes.New}
          />
        )}
      </div>
    );
  }
}

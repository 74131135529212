import React, { useState, useCallback } from "react";
import { Checkbox } from "@material-ui/core";
import Modal from "../../../components/Modal";
import PhoneInput from "../../../components/form/PhoneInput";
import CustomSelect from "../../../components/form/CustomSelect";
import ValidatedInput from "../../../components/form/ValidatedInput";
import { formatFullName } from "../../../lib";

export default function ChaperoneModal(props) {
  const {
    chaperones, // existing chaperones that were already chosen/saved
    chabadHousePersonnel,
    termsAndConditions,
    onSubmit,
    onClose,
  } = props;

  const [validation, setValidation] = useState({});
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [chaperone, setChaperone] = useState({
    personID: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    relationship: "",
    isChaperone: true,
  });

  const personnelOptions = chabadHousePersonnel
    .filter((p) => !chaperones.find((c) => c.personID === p.personID))
    .map((p) => {
      return {
        label: formatFullName(p.firstName, p.lastName),
        value: p.personID,
      };
    });

  const chaperoneTerms = termsAndConditions
    .filter((t) => t.appliesTo === "Chaperones")
    .map((t) => t.text);

  const personnelWasSelected = useCallback(() => {
    return !!chaperone.personID;
  }, [chaperone]);

  const hasValidChaperoneData = useCallback(() => {
    return (
      chaperone.firstName &&
      chaperone.lastName &&
      chaperone.phoneNumber &&
      chaperone.relationship &&
      !validation.phoneNumber
    );
  }, [chaperone, validation]);

  const hasAnyManualData = useCallback(() => {
    return (
      chaperone.firstName ||
      chaperone.lastName ||
      chaperone.phoneNumber ||
      chaperone.relationship
    );
  }, [chaperone]);

  const isValid = useCallback(() => {
    if (chaperoneTerms.length && !agreedToTerms) {
      return false;
    }
    return personnelWasSelected() || hasValidChaperoneData();
  }, [
    chaperoneTerms,
    agreedToTerms,
    personnelWasSelected,
    hasValidChaperoneData,
  ]);

  const onSave = useCallback(async () => {
    if (!isValid()) return;

    if (personnelWasSelected()) {
      const selectedPersonnel = chabadHousePersonnel.find(
        (p) => p.personID === chaperone.personID,
      );
      onSubmit({
        personID: selectedPersonnel.personID,
        firstName: selectedPersonnel.firstName,
        lastName: selectedPersonnel.lastName,
        isChaperone: true,
        relationship: "Personnel",
      });
    } else {
      onSubmit(chaperone);
    }
    onClose();
  }, [
    chaperone,
    chabadHousePersonnel,
    onClose,
    isValid,
    personnelWasSelected,
    onSubmit,
  ]);

  const onChangePhoneInput = (phoneNumber, phoneCountryID) => {
    setChaperone({ ...chaperone, phoneNumber, phoneCountryID });
  };

  const onChangeChaperone = (name, value) => {
    setChaperone({ ...chaperone, [name]: value });
  };

  const onChangeValidation = (name, isValid) => {
    setValidation({ ...validation, [name]: isValid });
  };

  return (
    <Modal show={true}>
      <div className="modal-container chaperone-modal-container">
        <div className="modal-card card chaperone-modal relative">
          <p className="xl-text fw-900 mb-24 mobile-mt-12">New Chaperone</p>
          <p className="accent-text-dark mb-16">
            Select from existing personnel or add new below
          </p>
          <div className="flex flex-align-center mb-32 mobile-block">
            <CustomSelect
              name="personID"
              className="custom-select"
              classNamePrefix="custom-select"
              isSearchable={true}
              menuPlacement={"bottom"}
              placeholder={"Select from personnel"}
              isClearable={true}
              onChange={onChangeChaperone}
              options={personnelOptions}
              value={chaperone.personID}
              disabled={hasAnyManualData()}
            />
          </div>
          <div className="flex mb-16 mobile-mb-8 mobile-flex-column">
            <div className="flex flex-align-center mobile-block mobile-mb-8 mr-12 chaperone-field">
              <label className="accent-text-dark">First Name</label>
              <ValidatedInput
                input={
                  <input
                    type="text"
                    className="custom-input"
                    disabled={personnelWasSelected()}
                  />
                }
                name="firstName"
                onChange={(e) =>
                  onChangeChaperone(e.target.name, e.target.value)
                }
                value={chaperone.firstName}
              />
            </div>
            <div className="flex flex-align-center mobile-block chaperone-field">
              <label className="accent-text-dark">Last Name</label>
              <div className="flex flex-column">
                <ValidatedInput
                  input={
                    <input
                      type="text"
                      className="custom-input mb-4"
                      disabled={personnelWasSelected()}
                    />
                  }
                  name="lastName"
                  onChange={(e) =>
                    onChangeChaperone(e.target.name, e.target.value)
                  }
                  value={chaperone.lastName}
                />
              </div>
            </div>
          </div>
          <div className="flex mb-24 mobile-mb-12 mobile-flex-column">
            <div className="flex flex-align-center mobile-block mobile-mb-8 mr-12 chaperone-field">
              <label className="accent-text-dark">Relationship</label>
              <div className="flex flex-align-center">
                <ValidatedInput
                  input={
                    <input
                      type="text"
                      className="custom-input mb-4"
                      disabled={personnelWasSelected()}
                    />
                  }
                  name="relationship"
                  onChange={(e) =>
                    onChangeChaperone(e.target.name, e.target.value)
                  }
                  value={chaperone.relationship}
                />
              </div>
            </div>
            <div className="flex flex-align-center mobile-block chaperone-field">
              <label className="accent-text-dark">Phone</label>
              <PhoneInput
                className="phone-input"
                name="phoneNumber"
                countryId={chaperone.phoneCountryID}
                onChange={onChangePhoneInput}
                error={!!validation.phoneNumber}
                validate={(isValid) =>
                  onChangeValidation("phoneNumber", !isValid)
                }
                value={chaperone.phoneNumber}
                disabled={personnelWasSelected()}
              />
            </div>
          </div>
          {!!chaperoneTerms.length && (
            <div className="flex flex-align-top">
              <Checkbox
                onClick={() => setAgreedToTerms(!agreedToTerms)}
                checked={agreedToTerms}
              />
              <div className="terms-text fw-300">
                {chaperoneTerms.map((t) => (
                  <p key={t} className="mb-8">
                    {t}
                  </p>
                ))}
              </div>
            </div>
          )}
          <hr className="chaperone-modal-separator" />
          <div className="modal-btns flex-align-center mt-16">
            <button
              className="btn cancel-btn link-text uppercase-text fw-500"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="btn save-btn link-text uppercase-text fw-500 ml-24"
              disabled={!isValid()}
              onClick={onSave}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

import React from "react";
import { Button, Typography } from "@material-ui/core";
import { getIsSmsCreditNeeded, MessagePricing } from "./WizardHelpers";

interface CostEstimateProps {
  error?: string;
  onAddCredit?: () => void;
  onClickCalculate?: () => void;
  pricingData: MessagePricing;
  recipientCount?: number;
}

export function SmsCostEstimate(props: CostEstimateProps) {
  const { error, onAddCredit, onClickCalculate, pricingData, recipientCount } =
    props;
  const creditNeeded = getIsSmsCreditNeeded(pricingData);
  return (
    <div>
      <div className="compose-message-calculate-header flex">
        <Typography variant="h6" className="mr-24">
          Estimated cost: $
          {pricingData.price ? pricingData.price.toString() : ""}
        </Typography>
        {onClickCalculate && (
          <Button
            color="secondary"
            size="small"
            variant="contained"
            onClick={onClickCalculate}
          >
            Calculate
          </Button>
        )}
      </div>
      {error && <div className="error-text mt-8">{error}</div>}
      <div className="compose-message-calculate">
        <div className="compose-message-row mb-12 ">
          <label className="accent-text mr-20">Segments</label>
          <span className="compose-message-calculate-val">
            {pricingData.segments || ""}
          </span>
        </div>
        <div className="compose-message-row mb-12 ">
          <span className="accent-text mr-20">Recipients</span>
          <span className="compose-message-calculate-val">
            {(pricingData.price && recipientCount) || ""}
          </span>
        </div>
        {pricingData.balance && (
          <div className="compose-message-row mb-12">
            <div
              className={`accent-text mr-16 ${
                creditNeeded ? "error-text" : ""
              }`}
            >
              Your account balance is $
              {pricingData.balance.toFixed(2).toString()}
            </div>
            {onAddCredit && (
              <Button
                color="secondary"
                size="small"
                variant="text"
                onClick={onAddCredit}
              >
                Add Credit
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
